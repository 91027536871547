import styled, { css } from 'styled-components'
import { Close, Done } from '@material-ui/icons'
import { typography } from 'styled-system'

export const HeaderActiveBorder = css`
  ${({ isActive, theme }) =>
    isActive
      ? `
        border: 2px solid ${theme.palette.primary.main};
        border-bottom: unset;
        box-shadow: unset;
        background-color: ${theme.palette.black.lighter};
        &::after {
          content: 0;
          border-bottom: 1.35504px solid ${theme.palette.black.lightest};
        }
        `
      : ''}
`

export const HeaderBorder = css`
  ${({ border, theme }) =>
    border
      ? `
        border-radius: 3px 3px 0px 0px;
        border: 2px solid ${theme.palette.black.lighter};
        box-sizing: border-box;
        border-bottom: 1.35504px solid ${theme.palette.black.lighter};
        border-bottom-left-radius: -2px;
        border-bottom-right-radius: -2px;
        `
      : ``}
`

const PriceActiveState = css`
  color: ${(props) => props.theme.palette.black.dark};
  .price-container {
    &::after {
      content: '/mo';
      padding-left: ${(props) => props.theme.spacing(1)}px;
      color: ${(props) => props.theme.palette.black.light};
      font-weight: normal;
      font-size: 16px;
    }
  }
`

const PriceInactiveState = css`
  color: ${(props) => props.theme.palette.black.dark};
  .price-container {
    &::after {
      content: '/mo';
      padding-left: ${(props) => props.theme.spacing(1)}px;
      color: ${(props) => props.theme.palette.black.light};
      font-weight: normal;
      font-size: 16px;
    }
  }
`

const PricingCard = styled.div.attrs(({ isActive, isDisabled }) => ({
  role: 'button',
  'aria-pressed': isActive
}))`
  display: flex;
  cursor: ${(props) => !props.isDisabled && 'pointer'};
  flex-direction: column;
  height: 100%;
  padding: ${(props) => props.theme.spacing(1)}px;
  width: 100%;
  ${({ isActive, theme, isOpen }) =>
    isActive
      ? `
          background-color: ${theme.palette.white.main};
          color: ${theme.palette.primary.main};
          ${isOpen && `border-bottom: 1px solid ${theme.palette.black.lightest}`}
          `
      : `
          background-color: ${theme.palette.black.lightest};
          color: ${theme.palette.black.lightestText};
          `}
`

// HEADER COMPONENT STYLES
PricingCard.Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`

PricingCard.PromotedPlan = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #ffbf1c;
  color: ${(props) => props.theme.palette.black.main};
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
`

PricingCard.PromotedText = styled.span`
  font-size: 24px;
  font-weight: bold;
`

PricingCard.PlanTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
`

PricingCard.InfoSection = styled.div`
  display: grid;
  grid-template-rows: 15px 40px 15px;
`

PricingCard.Info = styled.div`
  grid-row: 3;
`

PricingCard.InfoTitle = styled.div`
  grid-row: 2;
  display: flex;
  align-self: center;
  font-size: 14px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  text-align: center;
  margin: auto;
  ${typography}
`

PricingCard.PriceSection = styled.div`
  border-radius: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  font-size: 16px;
  padding: ${(props) => props.theme.spacing(2)}px 28px;
  ${({ isActive }) => (isActive ? PriceActiveState : PriceInactiveState)}
`

PricingCard.PriceContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  grid-row: 3;
  justify-content: center;
  position: relative;
`

PricingCard.Price = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

PricingCard.PriceDollars = styled.div`
  font-size: 48px;
  line-height: 2.4rem;
  align-self: flex-end;
`

PricingCard.Currency = styled.div`
  font-size: 24px;
`

PricingCard.PriceCents = styled.div`
  display: flex;
  align-self: flex-end;
  font-size: 16px;
`

PricingCard.PriceExtra = styled.div`
  line-height: 2.4rem;
  align-self: flex-end;
  font-size: 16px;
`

PricingCard.OriginalPrice = styled.div`
  grid-row: 4;
  text-decoration: line-through;
  padding: ${(props) => props.theme.spacing(2)}px 0;
  &::before {
    content: '$ ';
  }
  &::after {
    content: ' /mo';
  }
`
// FEATURE LIST COMPONENT STYLES
PricingCard.FeaturesList = styled.div.attrs(({ isActive, ...props }) => ({
  role: 'button',
  'aria-pressed': isActive
}))`
  cursor: ${(props) => !props.isDisabled && 'pointer'};
  font-weight: 500;
  ._perDay {
    &::after {
      content: ' /day';
    }
  }

  ._perMonth {
    &::after {
      content: '/month';
    }
  }

  ._strikeThrough {
    text-decoration: line-through;
  }
`

PricingCard.Close = styled(Close)`
  ${({ isActive }) => (isActive ? 'color: rgba(255, 255, 255, 0.5)' : 'color: rgba(0, 0, 0, 0.15)')}
`

PricingCard.Done = styled(Done)``

PricingCard.FeatureText = styled.span``

export default PricingCard
