import get from 'lodash/get'
import each from 'lodash/each'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import isFunction from 'lodash/isFunction'
import camelCase from 'lodash/camelCase'

export function GetGtmSessionAttributes () {
  const authState = window.authState.getValue()

  const isAuthenticated = get(authState, 'isAuthenticated')
  const isFreeAccount = get(authState, 'isFreeAccount')

  const userType = !isAuthenticated ? 'anonymous' : (isFreeAccount ? 'free' : 'paid')
  // todo add employer

  const userId = get(authState, 'globalIdentity') || ''
  const currency = get(authState, 'billingInfo.code')

  return {
    userType: userType,
    userId: userId,
    currency: currency
  }
}

export function GetGtmPageAttributes (pageId, pageType) {
  const pageTypeStr = isFunction(pageType) ? pageType() : pageType

  return {
    pageApplication: 'back-of-site',
    pageType: pageTypeStr,
    pageId: pageId,
    pageClass: '',
    brand: getRuntimeConfig()._brand,
    env: getRuntimeConfig()._env
  }
}

export function GetGtmVpvAttributesFromActivePage (activePath, flowType) {
  const flowTypeStr = isFunction(flowType) ? flowType() : flowType

  return {
    vpvFlow: flowTypeStr,
    vpvStep: activePath
  }
}

export function GetGtmProductsFromObj (products) {
  const obj = {
    product_id: [],
    product_name: [],
    product_brand: [],
    product_variant: [],
    product_category: [],
    product_price: [],
    product_quantity: []
  }

  const requiredKeysOnProduct = ['productId', 'productName', 'productTerm', 'productPrice', 'productQuantity']
  each(products, p => {
    each(requiredKeysOnProduct, key => {
      if (!p.hasOwnProperty(key)) {
        console.error(
          '!!! MUST FIX !!!, productId must be set for ecom tracking/n find our more: https://confluence.endurance.com/display/EWB/Analytics+Tracking'
        )
      }
    })

    if (!p.productCategory) {
      const productId = camelCase(p.productId)
      if (productId && productId.indexOf('plan') !== -1) {
        // appmarket sites
        p.productCategory = 'site'
      } else if (productId && productId.indexOf('domain') !== -1) {
        // appmarket domain
        p.productCategory = 'domain'
      } else {
        // For now, track all misc as appmarket apps (also gsuite and email) we can extend this later
        p.productCategory = 'apps'
      }
    }

    obj.product_id.push(camelCase(p.productId))
    obj.product_brand.push(camelCase(getRuntimeConfig()._brand))
    obj.product_name.push(camelCase(p.productName))
    obj.product_variant.push(camelCase(p.productId) + '_' + camelCase(p.productTerm))
    obj.product_category.push(camelCase(p.productCategory))
    obj.product_price.push(p.productPrice)
    obj.product_quantity.push(p.productQuantity)
  })
  return obj
}
