import React, { useEffect, useState } from 'react'
import map from 'lodash/map'
import get from 'lodash/get'

import ComparePlansModalContainer from './../containers/compare-plans-modal'
import useLimitation from '@eig-builder/core-utils/hooks/useLimitation'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import { getQueryParametersFromUrl } from '@eig-builder/core-utils/helpers/url-helper'

const UpgradeFreePlanModal = () => {
  const [state, setState] = useState({})
  const partnerId = getRuntimeConfig()._partnerId
  const [limitations, doFetch] = useLimitation('controlpanel',
    partnerId,
    null)

  const getExtraInfoFromUrl = () => {
    const newState = getQueryParametersFromUrl(window.location.search)
    try {
      return JSON.parse(newState.extraInfo)
    } catch (ex) {}
  }

  useEffect(() => {
    const extraInfo = getExtraInfoFromUrl()
    setState({ ...state, extraInfo })
    doFetch(extraInfo.siteId)
  }, [])

  useEffect(() => {
    const { isLoading, data } = limitations
    if (!state.limitation && limitations && !isLoading && data && data.site_id) {
      const siteLimitation = data.limitations?.site
      const newState = { ...state }
      newState.limitation = siteLimitation
      newState.upgradeSkus = map(get(siteLimitation, 'upgrades'), (item) => item.sku)
      newState.loaded = true
      newState.extraInfo.freeToPaid = true
      setState(newState)
    }
  }, [limitations])

  return state.loaded ? <ComparePlansModalContainer {...state} /> : null
}

export default UpgradeFreePlanModal
