import CenteredPageLoader from 'core/components/centred-page-loader'
import Loadable from 'react-loadable'

import * as pageTypes from '@eig-builder/core-utils/analytics/page-types'
import * as flowTypes from '@eig-builder/core-utils/analytics/flow-types'
import withBackgroundColor from 'core/withBackgroundColor'

const DELAYLOADER = 500

const Routes = [
  {
    pattern: '/onboarding/domain',
    pageTitle: '',
    isPublic: true,
    component: withBackgroundColor(Loadable({
      loader: () => import('./../container/domain-flow'),
      loading: CenteredPageLoader,
      delay: DELAYLOADER
    })),
    pageType: pageTypes.CHECKOUT,
    flowType: flowTypes.DOMAIN
  }
]

export default Routes
