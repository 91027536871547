import CenteredPageLoader from 'core/components/centred-page-loader'
import Loadable from 'react-loadable'
import withBackgroundColor from 'core/withBackgroundColor'

import * as pageTypes from '@eig-builder/core-utils/analytics/page-types'
import * as flowTypes from '@eig-builder/core-utils/analytics/flow-types'
const DELAYLOADER = 500

const Routes = [
  {
    pattern: '/onboarding-debug',
    pageTitle: 'Debug Onboarding',
    component: withBackgroundColor(Loadable({
      loader: () => import('../pages/debug-limitation-page'),
      loading: CenteredPageLoader,
      delay: DELAYLOADER
    })),
    isPublic: true,
    pathToLeaveFocusView: router => {
      window.history.back()
    },
    pageType: pageTypes.CHECKOUT,
    flowType: flowTypes.NONE
  }
]

export default Routes
