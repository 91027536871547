import { fetchAsJson } from '@eig-builder/core-utils/helpers/fetch-helper'
import { api } from '@eig-builder/core-utils/helpers/url-helper'

import {
  GET_PLANS,
  GET_DISCOUNT_DEFINITION
} from './actions-types'

export const getPlans = (siteId) => {
  return fetchAsJson(
    api(`/v1.0/billing/plans?siteId=${siteId}`),
    {
      key: GET_PLANS,
      dontShowErrorsInNotificationBar: true,
      convertToCamelCase: true
    })
}

export const getDiscountDefinition = (discountCode) => {
  return fetchAsJson(
    api(`/v1.0/billing/discount_definition?coupon=${discountCode}`),
    {
      key: GET_DISCOUNT_DEFINITION,
      dontShowErrorsInNotificationBar: true,
      convertToCamelCase: true
    })
}
