import { HeaderBorder, HeaderActiveBorder } from '../../pricing-card/pricing-card.style'
import Button from '@eig-builder/core-ui/Button'
import Typography from '@eig-builder/core-ui/Typography'

import styled from 'styled-components'

const HeaderButton = styled(Button)`
  margin: ${(props) => props.theme.spacing(2)}px !important;
`

const Header = styled.div`
  grid-row: 1;
  border-bottom: 1.35504px solid ${(props) => props.theme.palette.black.lightest};
  justify-content: flex-start;
  grid-column: ${(props) => props.index + 2};
  grid-row: 2 / 4;
  height: 100%;
  ${HeaderBorder}
  ${HeaderActiveBorder}
`

const Description = styled(Typography)`
  color: ${(props) => props.theme.palette.black.main};
  margin: ${(props) => props.theme.spacing(5)}px !important;
  max-height: 40px;
  text-align: center;
`

const PriceCalculatorContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing(10)}px;
`

const ModalFooterMessage = styled(Typography)`
  padding: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.palette.black.dark};
`

const DiscountPrice = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.palette.black.light};
  text-decoration: line-through;
`

export { HeaderButton, Header, Description, PriceCalculatorContainer, ModalFooterMessage, DiscountPrice }
