import CentredPageLoader from 'core/components/centred-page-loader'
import Loadable from 'react-loadable'
import withBackgroundColor from 'core/withBackgroundColor'

import CheckoutHelper from '@eig-builder/core-utils/helpers/checkout-helper'
import { getQueryParametersFromUrl } from '@eig-builder/core-utils/helpers/url-helper'
import withFocusNavigation from 'core/customWithFocusNavigationHoc'

import { withoutItemsNavigation } from '@eig-builder/module-navigation'

import { registerReducer } from '@eig-builder/core-utils/reducer-registry'
import './lang'
import ReducerFuncs from './../store'

import * as pageTypes from '@eig-builder/core-utils/analytics/page-types'
import * as flowTypes from '@eig-builder/core-utils/analytics/flow-types'

registerReducer('checkout', ReducerFuncs)

const Routes = () => {
  return [
    {
      pattern: '/addons/:resourceId?/:sku/:term',
      component: withBackgroundColor(withoutItemsNavigation(() => import('./../container/checkout-addons'))),
      pageTitle: 'Purchase your plan',
      documentTitle: localize => localize('modules.checkout.routes.myCheckout'),
      exactly: true,
      pageType: pageTypes.CHECKOUT,
      flowType: () => {
        // Flow type will be set when the checkout is opened or on the /plan page for legacy people
        let flowType = flowTypes.APPS
        try {
          flowType = window.sessionStorage.getItem('flowType')
        } catch (ex) {}
        return flowType || flowTypes.APPS
      }
    },
    {
      pattern: '/domain-search/:resourceId?',
      component: withBackgroundColor(withFocusNavigation(() => import('./../container/checkout-domain-search'))),
      pageTitle: 'Find your domain',
      documentTitle: localize => localize('modules.checkout.routes.myCheckout'),
      pageType: pageTypes.CHECKOUT,
      flowType: flowTypes.SITE
    },
    {
      pattern: '/domain-search2/:resourceId?',
      component: withBackgroundColor(withFocusNavigation(() => import('./../container/checkout-domain-search'))),
      pageTitle: 'Find your domain',
      documentTitle: localize => localize('modules.checkout.routes.myCheckout'),
      isPublic: true,
      pageType: pageTypes.CHECKOUT,
      flowType: flowTypes.SITE
    },
    {
      pattern: '/domain/:resourceId?/:sku/:term',
      component: withBackgroundColor(withFocusNavigation(() => import('./../container/checkout-domain'))),
      pageTitle: 'Domain purchase',
      documentTitle: localize => localize('modules.checkout.routes.myCheckout'),
      exactly: true,
      pageType: pageTypes.CHECKOUT,
      flowType: () => {
        // Flow type will be set when the checkout is opened or on the /plan page for legacy people
        let flowType = flowTypes.SITE
        try {
          flowType = window.sessionStorage.getItem('flowType')
        } catch (ex) {}
        return flowType || flowTypes.SITE
      }
    },
    {
      pattern: '/summary',
      component: withBackgroundColor(withoutItemsNavigation(() => import('../container/CheckoutSummaryContainer'))),
      pageTitle: 'Order summary',
      documentTitle: localize => localize('modules.checkout.routes.myCheckout'),
      exactly: true,
      pageType: pageTypes.CHECKOUT,
      flowType: () => {
        // Flow type will be set when the checkout is opened or on the /plan page for legacy people
        const flowType = window.sessionStorage.getItem('flowType')
        return flowType || flowTypes.APPS
      }
    },
    {
      pattern: '/plans/:resourceId?/:skus?',
      component: withBackgroundColor(withFocusNavigation(() => import('./../container/CheckoutPublicBuySiteComponent'))),
      pageTitle: 'Upgrade your site',
      documentTitle: localize => localize('modules.checkout.routes.myCheckout'),
      exactly: false,
      pageType: pageTypes.CHECKOUT,
      flowType: () => {
        // Flow type will be set when the checkout is opened or on the /plan page for legacy people
        try {
          window.sessionStorage.setItem('flowType', flowTypes.SITE)
        } catch (ex) {}
        return flowTypes.SITE
      }
    },
    {
      pattern: '/plans2/:resourceId?/:skus?',
      component: withBackgroundColor(withoutItemsNavigation(() => import('./../container/CheckoutPublicBuySiteComponent'))),
      pageTitle: 'Upgrade your site',
      documentTitle: localize => localize('modules.checkout.routes.myCheckout'),
      exactly: false,
      isPublic: true,
      pageType: pageTypes.CHECKOUT,
      flowType: () => {
        // Flow type will be set when the checkout is opened or on the /plan page for legacy people
        try {
          window.sessionStorage.setItem('flowType', flowTypes.SITE)
        } catch (ex) {}
        return flowTypes.SITE
      }
    },
    {
      pattern: '/domain-connect',
      pageTitle: 'Connect your domain',
      documentTitle: localize => localize('modules.checkout.routes.connectDomain'),
      exactly: true,
      component: withBackgroundColor(withFocusNavigation(() => import('./../container/checkout-connect-domain'))),
      pageType: pageTypes.CHECKOUT,
      flowType: flowTypes.DOMAIN
    },
    {
      pattern: '/editorUpgrade/:resourceId',
      exactly: false,
      pageTitle: 'Upgrade your site',
      documentTitle: localize => localize('modules.checkout.routes.myCheckout'),
      component: props => {
        const params = getQueryParametersFromUrl(props.location.search, true)
        CheckoutHelper.buySite(null, props.match.params.resourceId, null, 'editorUpgrade', params, {
          goToAction: url => {
            window.location.href = url
          }
        })
        return null
      },
      pageType: pageTypes.CHECKOUT,
      flowType: flowTypes.SITE
    },
    {
      pattern: '/redirect',
      component: withBackgroundColor(Loadable({
        loader: () => import('../../default-route-manager/container/default-route-manager'),
        loading: CentredPageLoader
      })),
      isPublic: true,
      pageType: pageTypes.CHECKOUT,
      flowType: flowTypes.NONE
    }
  ]
}

export default Routes
