import React, { memo, Fragment } from 'react'
import PropTypes from 'prop-types'
import Price from '@eig-builder/module-price'
import { getBrandName } from '@eig-builder/core-utils/helpers/url-helper'
import * as checkOutConstants from '../../../checkout/checkout-constants'

const UpgradeModalDomainPrice = (props) => {
  const domainPrice = checkOutConstants.DEFAULT_DOMAIN_PRICE_AFTER_CANCELLATION
  // Registration free and cancelation fee is not the same
  // if (props.currencyCode === 'GBP' || props.currencyCode === 'EUR') {
  //   domainPrice = 18
  // } Need this in future
  const domainPriceForGator = checkOutConstants.DEFAULT_DOMAIN_PRICE_AFTER_CANCELLATION_GATOR // 17.99
  return (
    <Fragment>
      {props.currencyCode &&
        <Price price={getBrandName() === 'HostGator'
      ? domainPriceForGator
      : domainPrice}
        />}
    </Fragment>)
}
UpgradeModalDomainPrice.propTypes = {
  currencyCode: PropTypes.string
}

export default memo(UpgradeModalDomainPrice)
