import React, { Component, Fragment } from 'react'
import hoistStatics from 'hoist-non-react-statics'
import Helmet from 'react-helmet'

/**
 * A public higher-order component to access the imperative API
 */
function withBackgroundColor (ChildComponent) {
  class ComponentWithCartItems extends Component {
    render () {
      return (<Fragment>
        <Helmet>
          <body data-with-background-color='true' />
        </Helmet>
        <ChildComponent
          {...this.props}
        />
      </Fragment>)
    }
  }
  return hoistStatics(ComponentWithCartItems, ChildComponent)
}

export default withBackgroundColor
