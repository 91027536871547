const FREE_PLAN_FEATURES = [true, true, 'Coming Soon', true, false, false, false, '1,000']

export const FREE_PLAN_FEATURES_MOBILE = [
  '1,000 Contacts',
  '2 Campaigns',
  '100 Unique E-mails',
  '100 Contact Uploads',
  'Schedule Campaigns',
  'WooCommerce Integration',
  'Multi-step Automations'
]

export const FREE_PLAN = {
  sku: 'PLAN',
  disabled: true,
  header: {
    plan: 'Free',
    info: ['', '0.00', null],
    description: 'Up to 1000 contacts and 5000 emails.'
  },
  features: FREE_PLAN_FEATURES
}

const AWESOME_PLAN_FEATURES = [true, true, 'Coming Soon', true, true, true, 'Coming Soon', 'Unlimited']

export const AWESOME_PLAN_FEATURES_MOBILE = [
  'Unlimited Contacts',
  'Unlimited Campaigns',
  'Unlimited Unique E-mails',
  '1,000 Contact Uploads',
  'Schedule Campaigns',
  'WooCommerce Integration',
  'Multi-step Automations'
]

export const AWESOME_PLAN = {
  sku: 'PLAN1_CE',
  header: {
    plan: 'Awesome',
    info: ['Starting from', '4.95', '6.00'],
    description: 'Unlimited contacts, campaigns and emails.'
  },
  features: AWESOME_PLAN_FEATURES
}

const ULTIMATE_PLAN_FEATURES = ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited', true, true, true]

export const ULTIMATE_PLAN_FEATURES_MOBILE = [
  'Unlimited Contacts',
  'Unlimited Campaigns',
  'Unlimited Unique E-mails',
  'Unlimited Contact Uploads',
  'Schedule Campaigns',
  'WooCommerce Integration',
  'Multi-step Automations'
]

export const ULTIMATE_PLAN = {
  sku: 'PLAN2_CE',
  header: {
    plan: 'Ultimate',
    info: ['Starting from', '6.00', '10.00'],
    description: 'All Starter features + automated emails.'
  },
  features: ULTIMATE_PLAN_FEATURES
}

export const ALL_PLANS = [FREE_PLAN, AWESOME_PLAN, ULTIMATE_PLAN]

export const ALL_PLANS_MOBILE = []

export const EMAIL_MARKETING_FEATURES = 'Email Marketing Features'

const INTELLIGENT_EMAIL_EDITOR = 'Intelligent Email Editor'

const WORDPRESS_BLOG_PROMOTION = 'WordPress Blog & Product Promotion'

const WOOCOMMERCE_INTEGRATION = 'WooCommerce Triggered Emails'

const CONTACTS_CRM = 'Contacts CRM'

const CAMPAIGN_SCHEDULER = 'Campaign Scheduler'

const MULTI_STEP_AUTOMATIONS = 'Multi-step Automations'

const TOTAL_CONTACTS = 'Total Contacts'

export const ALL_MARKETING_FEATURES = [
  INTELLIGENT_EMAIL_EDITOR,
  WORDPRESS_BLOG_PROMOTION,
  WOOCOMMERCE_INTEGRATION,
  CONTACTS_CRM,
  CAMPAIGN_SCHEDULER,
  MULTI_STEP_AUTOMATIONS,
  TOTAL_CONTACTS
]

export const STARTING_ROWS = {
  awesome: '1 / 4',
  ultimate: '2 / 4',
  free: '3 / 4'
}
