// import * as applicationTypes from './enums/application-types'

class PostMessageSender {
  constructor () {
    // Set Properties on Instance
    this.registeredIframes = {}
  }

  sendToIframe (iframe, message) {
    if (iframe && iframe.contentWindow && iframe.contentWindow.postMessage) {
      // send message
      iframe.contentWindow.postMessage(message, '*')
    }
  }

  sendMessageToIframes (message) {
    if (!message) {
      return
    }

    const iframes = Array.from(document.querySelectorAll('iframe[data-store="true"]'))
    iframes.forEach(iframe => {
      const applicationType = iframe.getAttribute('data-type').toLowerCase()

      if (message.targets && message.targets.indexOf(applicationType) !== -1) {
        this.sendToIframe(iframe, message)
      }
    })
  }

  sendMessage (message) {
    if (this._isServer) {
      this.sendMessageToIframes(message)
    } else if (window.opener && window.opener.postMessage) {
      window.opener.postMessage(message, '*')
    } else if (window.parent !== window && window.parent.postMessage) {
      window.parent.postMessage(message, '*')
    }
  }

  setIsServer (value) {
    this._isServer = value
  }

  createAndSendMessage (applicationTargets, messageType, data) {
    this.sendMessage({
      type: messageType,
      targets: applicationTargets,
      data: data
    })
  }

  createdMessage (applicationTargets, messageType, data) {
    return {
      type: messageType,
      targets: applicationTargets,
      data: data
    }
  }
}

export default new PostMessageSender()
