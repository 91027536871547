import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@eig-builder/core-ui/Typography'
import { LoaderWithBackdrop } from '@eig-builder/compositions-loader-with-timeout'

const CentredPageLoader = ({ error, pastDelay }) => {
  if (error) {
    console.error(error)
    return <Typography>{JSON.stringify(error)}</Typography>
  }
  return pastDelay && <LoaderWithBackdrop centeredX centeredY />
}
CentredPageLoader.propTypes = {
  error: PropTypes.object,
  pastDelay: PropTypes.bool
}
export default CentredPageLoader
