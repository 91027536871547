import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  modules: {
    upgradeModels: {
      comparePlansModalPlan: {
        advanceWebsiteStatistics: 'Advanced Website Statistics',
        basicWebsiteStatistics: 'Basic Website Statistics',
        buy2: 'Upgrade to',
        unlimited: 'Unlimited',
        none: 'None',
        no: 'No',
        basic: 'Basic',
        advanced: 'Advanced (integrated)',
        advanced1: 'Advanced',
        features: 'Features',
        websiteFormOnly: 'Website form only',
        yes: 'Yes',
        tbd: 'TBD',
        comingSoon: {
          title: 'Coming soon',
          description: 'This feature is coming soon.',
          close: 'Close'
        },
        storeProducts: '{value} Store Products',
        storeTransactionFee: '{value} Store transaction fee',
        shippingManagement: '{value} Shipping management',
        mostPopular: 'Most Popular',

        totalContacts: '{value} Total Contacts',
        customAudiences: '{value} Custom Audiences',
        paynow: 'Pay Now',
        premiumSections: '{value} Premium Sections',
        campaignsPerMonth: '{value} Campaigns per Month',
        uniqueEmailsPerMonth: '{value} Unique Emails per Month',
        csvUpload: 'CSV Upload',
        footerAds: 'Footer Ads'
      }
    }
  }
})
