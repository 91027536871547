import React from 'react'
import Skeleton from '@eig-builder/core-ui/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(Theme => ({
  text: {
    marginTop: Theme.spacing(1),
    marginBottom: Theme.spacing(2)
  },
  largeTextOne: {
    marginTop: 0
  },
  largeTextTwo: {
    marginTop: Theme.spacing(2)
  },
  grid: { display: 'grid', gridColumnGap: 10, gridRowGap: 30, justifyContent: 'center' }
}))

const getWindowDimensions = () => {
  if (typeof window === `undefined`) return { width: 0, height: 0 }
  const { innerWidth: width, innherHeight: height } = window
  return { width, height }
}

const ComparePlansSkeleton = () => {
  const skeletonStyles = useStyles()

  const { width } = getWindowDimensions()

  const col4 = width >= 670
  const col3 = width >= 550 && !col4
  const col2 = width >= 430 && !col4 && !col3

  const cols = col4 ? '170px 170px 170px 170px' : col3 ? '170px 170px 170px' : col2 ? '170px 170px' : '170px'

  return (
    <div className={skeletonStyles.grid} style={{ gridTemplateColumns: cols }}>
      <SkeletonHeader />
      {(col2 || col3 || col4) && <SkeletonHeader />}
      {(col3 || col4) && <SkeletonHeader />}
      {col4 && <SkeletonHeader />}

      <SkeletonBody />
      {(col2 || col3 || col4) && <SkeletonBody />}
      {(col3 || col4) && <SkeletonBody />}
      {col4 && <SkeletonBody />}
    </div>
  )
}

const SkeletonHeader = () => {
  const skeletonStyles = useStyles()
  const largeTextOne = classnames(skeletonStyles.text, skeletonStyles.largeTextOne)
  const largeTextTwo = classnames(skeletonStyles.text, skeletonStyles.largeTextTwo)

  return (
    <div>
      <div className='d-flex justify-content-center'>
        <Skeleton variant='text' width={135} height={16} />
      </div>
      <div className='d-flex pt-1 justify-content-center'>
        <div className='d-flex align-items-end'>
          <Skeleton className={largeTextOne} variant='rect' width={135} height={28} />
        </div>
      </div>
      <div className='d-flex pt-1 justify-content-center'>
        <Skeleton variant='text' width={165} height={16} />
      </div>
      <div className='d-flex pt-1 justify-content-center'>
        <div className='d-flex align-items-end'>
          <Skeleton className={largeTextTwo} variant='rect' width={150} height={32} />
        </div>
      </div>
    </div>)
}

const SkeletonBody = () => {
  const skeletonStyles = useStyles()
  const largeTextOne = classnames(skeletonStyles.text, skeletonStyles.largeTextOne)
  const largeTextTwo = classnames(skeletonStyles.text, skeletonStyles.largeTextTwo)

  return (
    <div>
      <div className='d-flex pt-1 justify-content-center'>
        <div className='d-flex align-items-end'>
          <Skeleton className={largeTextOne} variant='rect' width={135} height={28} />
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <Skeleton variant='text' width={135} height={16} />
      </div>
      <div className='d-flex pt-1 justify-content-center'>
        <Skeleton variant='text' width={165} height={16} />
      </div>
      <div className='d-flex justify-content-center'>
        <Skeleton variant='text' width={135} height={16} />
      </div>
      <div className='d-flex pt-1 justify-content-center'>
        <div className='d-flex align-items-end'>
          <Skeleton className={largeTextTwo} variant='rect' width={150} height={32} />
        </div>
      </div>
      <div className='d-flex pt-1 justify-content-center'>
        <Skeleton variant='text' width={165} height={16} />
      </div>
      <div className='d-flex pt-1 justify-content-center'>
        <div className='d-flex align-items-end'>
          <Skeleton className={largeTextOne} variant='rect' width={135} height={28} />
        </div>
      </div>
      <div className='d-flex pt-1 justify-content-center'>
        <div className='d-flex align-items-end'>
          <Skeleton className={largeTextTwo} variant='rect' width={150} height={32} />
        </div>
      </div>
    </div>)
}

export default ComparePlansSkeleton
