import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import Navigation, { PageContent } from '@eig-builder/module-navigation'
import { getCookie } from '@eig-builder/core-utils/helpers/cookie-helper'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import { getQueryParametersFromUrl, getUrl, siteNames } from '@eig-builder/core-utils/helpers/url-helper'
import { withRouter } from 'react-router'

import CircularProgress from '@eig-builder/core-ui/CircularProgress'
import { connect } from 'react-redux'

const BackButton = (props) => {
  return props.visible ? <Navigation.BackButton paddingRight='8px' {...props} /> : <div className='mr-3'>&nbsp;</div>
}
BackButton.propTypes = { visible: PropTypes.bool }

const mapStateToProps = (state) => {
  const isNonPaidCustomer = !(state.auth && state.auth.isPayedAccount)
  const inOnboardingFlow = isNonPaidCustomer // is in the onboarding flow not in the upgrade flow

  // Affiliates never have a back button on the onboarding flow
  // Brands where the PaidOnlyFlow is active (f.e. Gator) dont have the back button
  const isAffiliate = !!getCookie('FRT:VIS') || !!getCookie('ir.mid')
  let hideButton = false

  if (inOnboardingFlow && (isAffiliate || getRuntimeConfig()._brand === 'gator' || getRuntimeConfig()._brand === 'sitebuilder')) {
    hideButton = true
  }

  return {
    visible: !hideButton
  }
}

const BackButtonThatMightBeInvisible = connect(mapStateToProps)(BackButton)

const customWithFocusNavigation = Comp => {
  const LazyComp = React.lazy(Comp)
  class WithFocusNavigation extends React.Component {
    static propTypes = {
      history: PropTypes.object
    }

    onClick = () => {
      const params = getQueryParametersFromUrl(this.props.history.location.search, true)

      if (this.props.history.location.search.indexOf('local_d=true') >= 0) {
        this.props.history.goBack()
      } else if (params.uos && params.uos.indexOf('editor') >= 0) {
        window.close()
      } else {
        const backUri = params.returnurl || params.success
        if (backUri) {
          window.location.href = backUri
        } else {
          window.location.href = getUrl(siteNames.MY, '/home')
        }
      }
    }

    render () {
      return (
        <Navigation>
          <Navigation.DetailBar>
            <Navigation.LeftAlign>
              <BackButtonThatMightBeInvisible onClick={this.onClick} />
              <Navigation.BreadCrumbs />
            </Navigation.LeftAlign>
          </Navigation.DetailBar>
          <PageContent>
            <Suspense fallback={<CircularProgress centered withPadding />}>
              <LazyComp {...this.props} />
            </Suspense>
          </PageContent>
        </Navigation>
      )
    }
  }
  return withRouter(WithFocusNavigation)
}

export default customWithFocusNavigation
