import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './lang'
import Text from '@eig-builder/module-localization'

class Term extends Component {
  static propTypes = {
    term: PropTypes.number.isRequired,
    style: PropTypes.object,
    className: PropTypes.string
  }

  render () {
    const { term, style, className } = this.props

    if (term === 1) {
      return <Text className={className} style={style} message='modules.checkout.components.termelement.monthly' />
    } else if (term > 1) {
      const message =
        term <= 12 ? 'modules.checkout.components.termelement.year' : 'modules.checkout.components.termelement.years'
      return <Text className={className} style={style} message={message} values={[term / 12]} />
    }
    return null
  }
}

export default Term
