export const START_ONBOARDING_PAID = 'start_onboarding_paid'

const creativEmailPlanPromotions = {
  PLAN1_CE: {
    title: 'Starter Plan',
    type: 'site',
    pricing: [
      {
        term: 12,
        price: 10740,
        monthlyPrice: 895
      }
    ],
    metadata: {
      sku: 'PLAN1_CE'
    }
  },
  PLAN2: {
    title: 'Free Plan',
    type: 'site',
    pricing: [
      {
        term: 12,
        price: 0,
        monthlyPrice: 0
      }
    ],
    metadata: {
      sku: 'PLAN2'
    }
  },
  PLAN3: {
    title: 'Express Plan',
    type: 'site',
    pricing: [
      {
        term: 12,
        price: 12000,
        monthlyPrice: 1000
      }
    ],
    metadata: {
      sku: 'PLAN3'
    }
  },
  PLAN4: {
    title: 'Pro Plan',
    type: 'site',
    pricing: [
      {
        term: 12,
        price: 18000,
        monthlyPrice: 1500
      }
    ],
    metadata: {
      sku: 'PLAN4'
    }
  }
}

export const getCreativEmailPlanPromotion = sku => {
  switch (sku) {
    case 'PLAN2':
    case 'PLAN3':
    case 'PLAN4':
    case 'PLAN1_CE':
      return creativEmailPlanPromotions[sku]
    default:
      throw new Error('No plan for SKU: ' + sku)
  }
}
