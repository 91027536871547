import * as flowTypes from '@eig-builder/core-utils/analytics/flow-types'

const FLOWTYPE_SESSION_STORAGE_KEY = 'active-flow-type'
export const getFlowType = () => {
  try {
    const flowType = window.sessionStorage && window.sessionStorage.getItem(FLOWTYPE_SESSION_STORAGE_KEY)
    return flowType || flowTypes.NONE
  } catch (ex) {
    console.log(ex)
  }
}
export const setFlowType = (flow) => {
  try {
    window.sessionStorage && window.sessionStorage.setItem(FLOWTYPE_SESSION_STORAGE_KEY, flow)
  } catch (ex) {
    console.log(ex)
  }
}
