import 'core-js/stable'
// import 'core-js/shim' // included < Stage 4 proposals
// import 'regenerator-runtime/runtime'

// Add runtime config to singular runtime-config-helper
import { setRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
setRuntimeConfig(window.runtimeConfig)

/* eslint-disable import/first */
// First init raygun helper, So it will catch all exceptions!
import { init } from '@eig-builder/core-utils/helpers/apm/datadog-helper'
import { render } from 'react-dom'
import React from 'react'
import Root from 'app'

import './sass/main.scss'
/* eslint-enable import/first */
/* eslint-disable */
if (!window.EventSource) {
  import(/* webpackChunkName: "event-source-polyfill" */ 'event-source-polyfill') // eslint-disable-line no-unused-expressions
}

if (!window.Intl) {
  import(/* webpackChunkName: "intl" */ 'intl') // eslint-disable-line no-unused-expressions
  import(/* webpackChunkName: "intl-en" */ 'intl/locale-data/jsonp/en.js') // eslint-disable-line no-unused-expressions
}

init()

render(<Root />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept('app', () => {
    const NewRoot = require('app').default
    
    render(<NewRoot />, document.getElementById('root'))
  })
}
