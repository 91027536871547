const GatorPlanFeatures = {
  PLAN1: { // Starter plan
    STORE_PRODUCTS: '3',
    STORE_TRANSACTION_FEE: '3%',
    SHIPPING_MANAGEMENT: 'basic' // this needs to be translated
  },
  PLAN3: { // Premium plan
    STORE_PRODUCTS: '10',
    STORE_TRANSACTION_FEE: '3%',
    SHIPPING_MANAGEMENT: 'basic'
  },
  PLAN4: { // eCommerce plan
    STORE_PRODUCTS: 'unlimited',
    STORE_TRANSACTION_FEE: 'none',
    SHIPPING_MANAGEMENT: 'integratedShipping'
  }
}

export default GatorPlanFeatures
