import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  modules: {
    checkout: {
      components: {
        termelement: {
          months: '{0} months',
          monthly: 'Monthly',
          year: '{0} Year',
          years: '{0} Years'
        }
      }
    }
  }
})
