import { getApiKey } from '@eig-builder/core-utils/helpers/api-key-helper'

import {
  GetGtmSessionAttributes,
  GetGtmPageAttributes,
  GetGtmVpvAttributesFromActivePage,
  GetGtmProductsFromObj
} from './helpers/gtm-event-helper'

function debugLogger () {
  if (window?.builderShell.debugLogger) {
    console.log.apply(this, [`🔍 - analytics:`, ...arguments])
  }
}

// function gtm () {
//   window.dataLayer = window.dataLayer || []
//   window.dataLayer.push(arguments)
// }

class AnalyticsAPI {
  init (isBlueHost, fixedPagePattern) { //! fixedPagePattern is added in this checkout version! + find references
    let apiKey = getApiKey('google_tag_manager')
    if (isBlueHost) {
      apiKey = 'GTM-NKRRVKX'
    }
    if (!this.isInitialized && apiKey != null) {
      this.isInitialized = true
      this.fixedPagePattern = fixedPagePattern
      this.includeJs(apiKey)
      debugLogger('apiKey', apiKey)
    }
  }

  includeJs (apiKey) {
    // eslint-disable-next-line no-sequences, no-undef, no-sequences, one-var
    (function (d, s) {
      // We need to add this since its loaded async
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      })
      // eslint-disable-next-line no-sequences, no-undef, no-sequences, one-var
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s)
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm/js?id=' + apiKey
      f.parentNode.insertBefore(j, f)
    })(document, 'script')

    // gtm('config', 'GA_MEASUREMENT_ID', { send_page_view: false })

    // if (window.performance) {
    //   // Gets the number of milliseconds since page load
    //   // (and rounds the result since the value must be an integer).
    //   const timeSincePageLoad = Math.round(window.performance.now())

    //   // Sends the timing event to Google Analytics.
    //   gtm('event', 'timing_complete', {
    //     name: 'load-until-GA',
    //     value: timeSincePageLoad,
    //     event_category: 'JS Dependencies'
    //   })
    // }
  }

  /**
   * Triggered by Analytics Service - Do not call this methodes out side the analytics service
   *
   *
   * @memberOf GoogleTagManagerLogger
   */
  login (name, email, accountId, globalIdentity, routeObj) {
    // const { pageId, pageType, activePath, flowType, appName } = routeObj
    // if (this.isInitialized) {
    //   this.accountId = accountId
    //   this.globalIdentity = globalIdentity
    //   this.impactRadiusCampaignId = getApiKey('impact_radius_campaign_id')
    //   const data = {
    //     ...GetGtmSessionAttributes(),
    //     ...GetGtmPageAttributes(pageId, pageType),
    //     ...GetGtmVpvAttributesFromActivePage(activePath, flowType),
    //     event: 'UserLoggedIn',
    //     appName,
    //     userid: this.globalIdentity,
    //     accountId: this.accountId,
    //     impactRadiusCampaignId: this.impactRadiusCampaignId,
    //     event_category: 'UserActions'
    //   }
    //   debugLogger('login', data)
    //   gtm('event', 'login', data)
    // }
  }

  /**
   * Triggered by Analytics Service - Do not call this methodes out side the analytics service
   *
   *
   * @memberOf GoogleTagManagerLogger
   */
  trackPageView (path, routeObj) {
    const { pageId, pageType, activePath, flowType, appName } = routeObj
    if (this.isInitialized) {
      const data = {
        appName,
        ...GetGtmSessionAttributes(),
        ...GetGtmPageAttributes(pageId, pageType),
        ...GetGtmVpvAttributesFromActivePage(activePath, flowType),
        pagePattern: this.fixedPagePattern,
        // vpvStep: path, // override vpv step, because trackPageView event could be triggered manually
        page: {
          // title: document.title,
          url: path
        },
        userid: this.globalIdentity,
        accountId: this.accountId
      }
      debugLogger('trackPageView', data)
      window.dataLayer.push({
        event: 'virtualPageView',
        ...data
      })
    }
  }

  /**
   * Triggered by Analytics Service - Do not call this methodes out side the analytics service
   *
   *
   * @memberOf GoogleTagManagerLogger
   */
  trackUserDomains (domainCountObject, routeObj) {
    // const { pageId, pageType, activePath, flowType, appName } = routeObj
    // if (this.isInitialized) {
    //   const data = {
    //     appName,
    //     event: 'trackUserDomains',
    //     ...GetGtmSessionAttributes(),
    //     ...GetGtmPageAttributes(pageId, pageType),
    //     ...GetGtmVpvAttributesFromActivePage(activePath, flowType),
    //     page: {
    //       ...domainCountObject
    //     },
    //     userid: this.globalIdentity,
    //     accountId: this.accountId
    //   }
    //   debugLogger('trackUserDomains', data)
    //   gtm(data)
    // }
  }

  /**
   * Triggered by Analytics Service - Do not call this methodes out side the analytics service
   *
   *
   * @memberOf GoogleTagManagerLogger
   */
  trackEvent (action, obj, routeObj) {
    const { pageId, pageType, activePath, flowType, appName } = routeObj
    if (this.isInitialized) {
      if (action === 'AccountJoined') {
        obj.impactRadiusActionId = getApiKey('impact_radius_lead_action_id')
      }

      const data = {
        pagePattern: this.fixedPagePattern,
        ...GetGtmSessionAttributes(),
        ...GetGtmPageAttributes(pageId, pageType),
        ...GetGtmVpvAttributesFromActivePage(activePath, flowType),
        ...obj,
        appName
      }

      window.dataLayer.push({
        event: action,
        ...data
      })
    }
  }

  /**
   * Triggered by Analytics Service - Do not call this methodes out side the analytics service
   *
   *
   * @memberOf GoogleTagManagerLogger
   */
  trackEcommercePurchase (orderId, totalPriceExTax, totalTax, status, products, routeObj) {
    const { pageId, pageType, activePath, flowType, appName } = routeObj
    if (this.isInitialized) {
      const data = {
        event: 'trackEcommercePurchase',
        appName,
        ecommerce: {
          ...GetGtmSessionAttributes(),
          ...GetGtmPageAttributes(pageId, pageType),
          ...GetGtmVpvAttributesFromActivePage(activePath, flowType),
          ...GetGtmProductsFromObj(products),
          order_id: orderId,
          order_grand_total: totalPriceExTax,
          order_tax_amount: totalTax,
          order_shipping_amount: null,
          order_promo_code: null,
          affiliation: null
        }
      }
      debugLogger('trackEcommercePurchase', data)
      window.dataLayer.push(data)
    }
  }
}

export default AnalyticsAPI
