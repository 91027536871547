import React from 'react'
import CheckoutUrlFlow from './checkout-url-flow'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import { addQueryParamtersToUrl, getQueryParametersFromUrl } from '@eig-builder/core-utils/helpers/url-helper'
import Term from 'core/checkout/components/term-element'
import { getCookie } from '@eig-builder/core-utils/helpers/cookie-helper'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import * as checkoutConstants from '../checkout-constants'
import { withRouter } from 'react-router'
import getPurchaseTrigger from 'core/purchase-helper'

class CheckoutHelpers {
  static createOrderObj (history, match, location, products, getNextUrl, paymentMethod, couponCode, purchaseOrigin, purchaseLimitation) {
    return {
      order_items: products.map(product => {
        let extractedResourceID = product.resourceId ? product.resourceId : CheckoutUrlFlow.getResourceId(match, location)
        if (!extractedResourceID) {
          extractedResourceID = getQueryParametersFromUrl(location.search)['site-id']
        }
        return {
          term: product.term,
          sku: product.sku,
          resource_id: extractedResourceID,
          type: product.type || 'standard'
          // the default type is standard
        }
      }),
      payment_method: paymentMethod || 'none', // when none is passed JP does the known payment method
      // purchase_origin: CheckoutUrlFlow.getUos(history),
      purchase_state_id: CheckoutUrlFlow.getPurchaseStateId(history),
      cancel_url: window.location.href,
      return_url: window.location.origin + getNextUrl(),
      error_url: window.location.href + (window.location.href.indexOf('?') < 0 ? '?' : '&') + 'error=true',
      coupon_code: couponCode || CheckoutUrlFlow.getCouponCode(history),
      purchase_origin: purchaseOrigin,
      purchase_trigger: getPurchaseTrigger(`${purchaseLimitation}`)
    }
  }

  static updatePurchaseStateIdInOrder (order, purchaseStateId) {
    if (order) {
      order.cancel_url = addQueryParamtersToUrl(order.cancel_url, { psid: purchaseStateId })
      order.return_url = addQueryParamtersToUrl(order.return_url, { psid: purchaseStateId })
      order.error_url = addQueryParamtersToUrl(order.error_url, { psid: purchaseStateId })
      order.purchase_state_id = purchaseStateId
    }
  }

  static mapPricesToArray (prices, discount, advancedDiscount) {
    if (prices) {
      return map(prices, price => {
        const returnObj = {
          discount: price.discount,
          discountIsPercentage: price.discountIsPercentage || price.discount_is_percentage,
          discountTerm: price.discountTerm || price.discount_term,
          value: price.term,
          price: price.monthlyPrice || price.monthly_price,
          totalPrice: price.price,
          label: <Term term={price.term} />,
          term: price.term,
          vat: price.vat
        }

        if (isEmpty(advancedDiscount) === false) {
          const termDiscount = find(advancedDiscount, ad => ad.term === price.term || ad.term === null)

          if (isNil(termDiscount) === false) {
            if (termDiscount.discount_type === 0) {
              returnObj.discountPercentage = termDiscount.value
            } else if (termDiscount.discount_type === 1) {
              returnObj.discountAmount = termDiscount.value
            }
          }
          return returnObj
        }

        returnObj.save = discount || 0
        return returnObj
      })
    }
    return []
  }

  static calcUpgradePlanToMaxPrice (plan, currentTerm) {
    if (!plan.maxTerm) {
      return 0
    }

    const maxTerm = plan.maxTerm

    currentTerm = Math.max(1, Math.min(currentTerm, maxTerm.term))

    if (!plan.prices[currentTerm]) {
      return
    }
    const currentPrice = plan.prices[currentTerm].price
    return Math.max(maxTerm.price - currentPrice, 0)
  }

  // used for showing spinner after some time after while the cc auth call is running
  static timeAgo (timestamp) {
    const nowTimestamp = CheckoutHelpers.getTimeStamp()
    const max = 3600 * 1000
    if (nowTimestamp < timestamp) {
      return max + (nowTimestamp - timestamp)
    }
    return nowTimestamp - timestamp
  }

  static getTimeStamp () {
    const a = new Date()
    return a.getMilliseconds() + a.getSeconds() * 1000 + a.getMinutes() * 60 * 1000
  }

  static getInitialCouponCode () {
    const cookieCoupon = getCookie('cp_coupon_campaign')
    if (!isEmpty(cookieCoupon)) {
      return cookieCoupon
    } else if (getRuntimeConfig()._brand === 'gator') {
      return checkoutConstants.DEFAULT_GATOR_COUPON
    } else if (getRuntimeConfig()._brand === 'websitebuilder') {
      return checkoutConstants.WEBSITEBUILDER_DEFAULT_CODE
    } else if (getRuntimeConfig()._brand === 'creativemail') {
      // EWE-5099 Remove default discount for creativemail to check if it has influence on sales
      // return checkoutConstants.DEFAULT_CREATIVEMAIL_COUPON
      return ''
    } else if (getRuntimeConfig()._brand === 'constantcontact') {
      return ''
    } else {
      return checkoutConstants.SITEBUILDER_DEFAULT_CODE
    }
  }
}

export default withRouter(CheckoutHelpers)
