// Redux
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import { setCouponCode, resetCouponCode, setCouponDefinition } from 'core/cart/utility'

import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import { registerReducer } from '@eig-builder/core-utils/reducer-registry'
import Component from '../../components/compare-plans-modal'
import ComponentWithSkeleton from '../../components/compare-plans-modal-with-skeleton'
import CheckoutHelpers from './../../../checkout/helpers/checkout-helpers'

import { listenToFetchActions } from '@eig-builder/core-utils/helpers/reducer-helper'

import { GET_PLANS, GET_DISCOUNT_DEFINITION } from './store/actions-types'

import { getPlans, getDiscountDefinition } from './store/actions'

const reducer = (state = { retrieving: true }, action) => {
  return (
    listenToFetchActions(state, action, GET_DISCOUNT_DEFINITION) ||
    listenToFetchActions(state, action, GET_PLANS) ||
    state
  )
}

const mapStateToProps = (state) => {
  const {
    comparePlansModal: {
      getPlansResponse,
      getPlansRetrieving,
      getDiscountDefinitionResponse,
      getDiscountDefinitionRetrieving
    }
  } = state
  const discountDefinitions = get(getDiscountDefinitionResponse, 'elements')
  const billingInfo = state.auth && state.auth.billingInfo
  // VAT Rate
  const vatPercentage = get(state, 'auth.billingInfo.taxRate')

  const currencyCode = get(state, 'auth.billingInfo.code')

  // is free account
  let discountCode = null
  const { _brand } = getRuntimeConfig()
  const isSiteyOrSitelio = _brand === 'sitelio' || _brand === 'sitey'

  if (!isSiteyOrSitelio) {
    discountCode = CheckoutHelpers.getInitialCouponCode()
  }

  return {
    vatPercentage,
    discountCode,
    currencyCode,
    discountDefinitions,
    billingInfo,
    getPlansResponse: state.comparePlansModal && getPlansResponse,
    isLoadingModal:
      getPlansRetrieving || getDiscountDefinitionRetrieving || isUndefined(getPlansResponse) || !billingInfo
  }
}

const mapDispatch = (dispatch) => {
  return {
    getPlans: bindActionCreators(getPlans, dispatch),
    getDiscountDefinition: bindActionCreators(getDiscountDefinition, dispatch)
  }
}

const LoadDiscountCodeIfAuthenticationAndAccountInfoIsLoaded = React.memo(function (props) {
  const { _brand } = getRuntimeConfig()
  const isCreativeMail = _brand === 'creativemail'

  useEffect(() => {
    props.discountCode && props.getDiscountDefinition(props.discountCode)
  }, [props.discountCode, props.promotedSku, props.promotedTerm])

  if (props.discountDefinitions && props.discountDefinitions.length > 0) {
    setCouponCode(props.discountCode)
    setCouponDefinition(props.discountDefinitions)
  } else {
    resetCouponCode()
  }
  if (isCreativeMail) {
    return <Component {...props} />
  }

  return (
    <ComponentWithSkeleton {...props} />
  )
})

LoadDiscountCodeIfAuthenticationAndAccountInfoIsLoaded.propTypes = {
  discountCode: PropTypes.string,
  promotedSku: PropTypes.string,
  promotedTerm: PropTypes.number,
  discountDefinitions: PropTypes.array,
  getDiscountDefinition: PropTypes.func
}

registerReducer('comparePlansModal', reducer)

const container = connect(mapStateToProps, mapDispatch)(LoadDiscountCodeIfAuthenticationAndAccountInfoIsLoaded)

export default container
