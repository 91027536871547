import styled from 'styled-components'

const ComparePlansModalFeature = styled.div`
height: 50px;
align-items: center;
display: flex;
border-top: 1px solid ${props => props.theme.palette.border.main};
border-right: 1px solid ${props => props.theme.palette.border.main};
${props => props.borderBottom ? 'border-bottom: 1px solid ' + props.theme.palette.border.main : ''};
${props => (props.borderLeft || props.isMobile) ? 'border-left: 1px solid ' + props.theme.palette.border.main : ''};
`

export default ComparePlansModalFeature
