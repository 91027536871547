import React, { memo, isValidElement, cloneElement } from 'react'
import PropTypes from 'prop-types'
import Typography from '@eig-builder/core-ui/Typography'
import map from 'lodash/map'

const UpgradeModalDisclaimerComponent = ({ title, ...props }) => {
  let child = title
  if (isValidElement(child)) {
    child = cloneElement(child, { ...props })
  }
  return (
    <div className='pt-3'>
      <Typography variant='body2'>{child}</Typography>
    </div>
  )
}
UpgradeModalDisclaimerComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

const UpgradeModalDisclaimer = ({ title, ...props }) => {
  if (Array.isArray(title)) {
    return map(title, (t) => {
      return <UpgradeModalDisclaimerComponent title={t} {...props} />
    })
  }

  return <UpgradeModalDisclaimerComponent title={title} {...props} />
}

UpgradeModalDisclaimer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.array])
}

export default memo(UpgradeModalDisclaimer)
