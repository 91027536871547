import CenteredPageLoader from 'core/components/centred-page-loader'
import Loadable from 'react-loadable'
import * as pageTypes from '@eig-builder/core-utils/analytics/page-types'
import withBackgroundColor from 'core/withBackgroundColor'
import { getFlowType } from 'core/flow-type-helper'

const DELAYLOADER = 500

const Routes = [
  {
    pattern: '/onboarding/modal',
    pageTitle: 'Checkout',
    component: Loadable({
      loader: () => import('./../pages/modal-page'),
      loading: CenteredPageLoader,
      delay: DELAYLOADER
    }),
    isPublic: true,
    pageType: pageTypes.CHECKOUT,
    flowType: () => getFlowType()
  },
  {
    pattern: '/onboarding/full-screen',
    pageTitle: 'redirect',
    component: withBackgroundColor(Loadable({
      loader: () => import('../pages/full-screen-page'),
      loading: CenteredPageLoader,
      delay: DELAYLOADER
    })),
    isPublic: true,
    pageType: pageTypes.CHECKOUT,
    flowType: () => getFlowType()
  },
  {
    pattern: '/onboarding/full-modal',
    pageTitle: 'redirect',
    component: Loadable({
      loader: () => import('../pages/full-modal-page'),
      loading: CenteredPageLoader,
      delay: DELAYLOADER
    }),
    isPublic: true,
    pageType: pageTypes.CHECKOUT,
    flowType: () => getFlowType()
  }
]

export default Routes
