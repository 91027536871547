import React from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@eig-builder/core-ui/FormControlLabel'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Radio from '@eig-builder/core-ui/Radio'

import Text from '@eig-builder/module-localization'
import useLocalize from '@eig-builder/module-localization/useLocalize'

import Chip from '@eig-builder/core-ui/Chip'

import { Header, HeaderButton, Description, DiscountPrice } from './views'

import PricingCard from '../pricing-card/pricing-card.style'

const HeaderComponent = ({
  index,
  isActive,
  currencySymbol,
  border,
  header,
  setSelectedPlan,
  handleOpenPriceCalculator,
  isOpen,
  open,
  disabled,
  isCurrentPlan
}) => {
  const { plan, info, description } = header // eslint-disable-line
  const [title, price] = info
  const [dollars, cents] = price.split('.')

  const { localize } = useLocalize()
  return (
    <Header border isActive={isActive} index={index}>
      <PricingCard
        border={border}
        isActive={isActive}
        isOpen={isOpen}
        onClick={() => !disabled && setSelectedPlan()}
        isDisabled={disabled}
      >
        <PricingCard.Header>
          <PricingCard.PlanTitle>
            {!disabled && (
              <FormControlLabel
                style={{ marginRight: 0 }}
                checked={isActive}
                value='default'
                control={
                  <Radio
                    dataElementLocation={DataElementLocations.POP_UP}
                    dataElementLabel='checkout--upgrade-modals--creativemail-radio-button'
                    dataElementId='checkout--upgrade-modals--creativemail-radio-button'
                  />
                }
              />
            )}
            {plan}
          </PricingCard.PlanTitle>
          <PricingCard.InfoTitle>
            {isCurrentPlan ? (
              <Chip
                color='primary'
                label={localize('modules.upgradeModels.creativeEmailModal.currentPlan')}
                size='small'
                customColor='primary.lighter'
              />
            ) : (
              title
            )}
          </PricingCard.InfoTitle>
          <PricingCard.PriceSection isActive={isActive} isDisable={disabled}>
            <PricingCard.Currency>{currencySymbol}</PricingCard.Currency>
            <PricingCard.PriceContainer className='price-container'>
              <PricingCard.Price>
                <PricingCard.PriceDollars>{dollars > 0 ? '2' : dollars}</PricingCard.PriceDollars>
                <PricingCard.PriceCents>.{dollars > 0 ? '00' : cents || '00'}</PricingCard.PriceCents>
                {dollars > 0 && <PricingCard.PriceExtra>*</PricingCard.PriceExtra>}
              </PricingCard.Price>
            </PricingCard.PriceContainer>
          </PricingCard.PriceSection>
          <DiscountPrice>{dollars > 0 && `${currencySymbol}${dollars}.${cents} /mo`}</DiscountPrice>
        </PricingCard.Header>
        {!isOpen && <Description variant='body2'>{description}</Description>}
        {dollars > 0 ? (
          <HeaderButton
            color='secondary'
            onClick={handleOpenPriceCalculator}
            dataElementLocation={DataElementLocations.POP_UP}
            dataElementLabel='checkout--upgrade-modals--creativemail-calculateprice-button'
            dataElementId='checkout--upgrade-modals--creativemail-calculateprice-button'
          >
            {open ? (
              <Text message='modules.upgradeModels.creativeEmailModal.calculatePrice' />
            ) : (
              <Text message='modules.upgradeModels.creativeEmailModal.compareFeatures' />
            )}
          </HeaderButton>
        ) : (
          <div style={{ height: '75px' }} />
        )}
      </PricingCard>
    </Header>
  )
}

HeaderComponent.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string,
  border: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  header: PropTypes.object.isRequired,
  setSelectedPlan: PropTypes.func.isRequired,
  handleOpenPriceCalculator: PropTypes.func.isRequired,
  isCurrentPlan: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired
}

HeaderComponent.defaultProps = {
  currencySymbol: '$',
  disabled: false
}

export default HeaderComponent
