import styled, { css } from 'styled-components'
import { flexbox, grid, position, layout } from 'styled-system'

const FeatureActiveCard = css`
  ${({ isActive, theme }) =>
    isActive
      ? `
        border: 2px solid ${theme.palette.primary.main};
        border-top: unset;
        position: relative;
        box-shadow: unset;
        &:after {
          content: '';
          position: absolute;
          transform: translate(0, 100%);
          bottom: 0;
          left: calc(50% - 70px);
          width: 0;
          height: 0;
          border-left: 70px solid transparent;
          border-right: 70px solid transparent;
          border-top: 30px solid ${theme.palette.primary.main};
        }
        `
      : ''}
`

const FeatureBorder = css`
  ${({ border, theme }) =>
    border
      ? `
      border-radius: 0px 0px 3px 3px;
      border: 2px solid ${theme.palette.black.lighter};
      box-sizing: border-box;
      border-top: unset;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: 0px 2px 2px 2px rgba(143, 142, 135, 0.1);
      `
      : ``}
`

const FeatureActiveState = css`
  ${({ theme, isActive }) =>
    isActive
      ? `
          background-color: ${theme.palette.white.main};
          color: ${theme.palette.primary.main};
        `
      : `
          background-color: ${theme.palette.black.lightest};
          color: ${theme.palette.black.main}; 
        `}

  ${({ isMarketingRow, theme }) =>
    isMarketingRow
      ? `
      grid-template-columns: minmax(0, 1fr);
      background-color: transparent;
      color: ${theme.palette.black.light};
      .list-item {
        font-weight: normal;
      }
    `
      : ''}
`

// 2 rows x 4 columns
const PricingSection = styled.section`
  display: grid;
  justify-content: center;
  grid-column-gap: 8px;
  grid-template-columns: minmax(265px, 160px) repeat(3, minmax(200px, 160px));
  grid-template-rows: 0 30px repeat(2, min-content);
  padding: ${(props) => props.theme.spacing(1)}px;
`

PricingSection.CardContainer = styled.div`
  ${({ isOpen, theme }) =>
    isOpen &&
  `  border-bottom: 1.35504px solid ${theme.palette.black.lightest};
`}

  grid-row: 1;
  justify-content: flex-start;
  ${grid}
  ${flexbox}
  ${position}
  ${layout}
`

PricingSection.EmailMarketingTitle = styled.span`
  color: ${(props) => props.theme.palette.black.main};
  font-size: 16px;
  font-weight: bold;
`

PricingSection.FeaturesWrapper = styled.div`
  grid-row: 2;
  ${flexbox}
  ${grid}
  ${position}
`

PricingSection.FeaturesContainer = styled.div`
  display: grid;
  grid-template-rows: ${(props) =>
    props.isOpen ? 'repeat(8, minmax(45px, 1fr)) 30px' : 'repeat(0, minmax(45px, 1fr)) 30px'};
  flex-direction: column;
  grid-auto-flow: column;
  grid-row: 2;
  width: 100%;
  
  .list-item {
    border-bottom: 1.35504px solid  ${(props) => props.theme.palette.black.lightest};
    display: flex;
    height: 100%;
    align-items: center;
    ${({ justifyCenter }) => (justifyCenter ? 'justify-content: center;' : '')}
  }
  
  ${FeatureActiveState}
  ${FeatureBorder}
  ${FeatureActiveCard}

  ${flexbox}
  ${grid}
  ${position}
`

PricingSection.SliderContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: ${(props) => props.theme.spacing(10)}px auto;

  ul {
    padding: 0;
  }
`

PricingSection.SliderTextSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

PricingSection.SliderTitle = styled.span``

export default PricingSection
