import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  modules: {
    upgradeModels: {
      comparePlansModal: {
        next: 'Continue to Payment',
        previous: 'Cancel',
        learnMoreAbout: 'Learn more about plan details and pricing on ',
        storeProducts: 'Store Products',
        storeTransactionFee: 'Store transaction fee',
        shippingManagement: 'Shipping management',
        totalContacts: 'Total Contacts',
        customAudiences: 'Custom Audiences',
        premiumSections: 'Premium Sections',
        campaignsPerMonth: 'Campaigns per Month',
        uniqueEmailsPerMonth: 'Unique Emails per Month',
        csvUpload: 'CSV Upload',
        footerAds: 'Footer Ads'
      },

      creativeEmailModal: {
        modalTitle: 'Upgrade your plan',
        currentPlan: 'Current Plan',
        calculatePrice: 'Calculate Your Price',
        compareFeatures: 'Compare Features',
        title: 'How many Contacts will you have?',
        subtitle: 'Contacts',
        cancelButton: 'Cancel',
        checkoutButton: 'Checkout',
        featuresTitle: 'Top Features',
        footerText: '* Total price excl. VAT. Non promotional pricing is calculated monthly based on the highest number of active contacts in the previous month. Maximum email sends per mo: Free 5,000, Awesome 45,000.',
        notificationMessage: {
          p1: 'Launch special!',
          p2: 'Get 6 months of',
          p3: 'Awesome',
          p4: 'for only',
          p5: '2!',
          s1: 'Spring Sale:',
          s2: 'Get 3 months of the',
          s3: 'Awesome',
          s4: 'plan for only',
          s5: '1/month'
        }
      }
    }
  }
})
