export const GET_OFFERS = 'GET_OFFERS'
export const GET_OFFERS_PENDING = 'GET_OFFERS_PENDING'
export const GET_OFFERS_FULFILLED = 'GET_OFFERS_FULFILLED'
export const GET_OFFERS_ERROR = 'GET_OFFERS_ERROR'

export const GET_TONCE = 'GET_TONCE'
export const GET_TONCE_PENDING = 'GET_TONCE_PENDING'
export const GET_TONCE_FULFILLED = 'GET_TONCE_FULFILLED'
export const GET_TONCE_ERROR = 'GET_TONCE_ERROR'

export const GET_PAYMENT_INFO = 'GET_PAYMENT_INFO'
export const GET_PAYMENT_INFO_PENDING = 'GET_PAYMENT_INFO_PENDING'
export const GET_PAYMENT_INFO_FULFILLED = 'GET_PAYMENT_INFO_FULFILLED'
export const GET_PAYMENT_INFO_ERROR = 'GET_PAYMENT_INFO_ERROR'

export const SUBMIT_FORM = 'SUBMIT_FORM'
export const SUBMIT_FORM_PENDING = 'SUBMIT_FORM_PENDING'
export const SUBMIT_FORM_FULFILLED = 'SUBMIT_FORM_FULFILLED'
export const SUBMIT_FORM_ERROR = 'SUBMIT_FORM_ERROR'

export const RESET_FORM_STATE = 'RESET_FORM_STATE'

export const TO_NEXT_FORM = 'TO_NEXT_FORM'

export const POST_CREDIT_CARD = 'POST_CREDIT_CARD'

export const RESET_SAVE_RESPONSE = 'RESET_SAVE_RESPONSE'

export const SAVE_CONTACT_INFO = 'SAVE_CONTACT_INFO'

export const GET_CURRENT_CC = 'GET_CURRENT_CC'

export const GET_CHECKOUT_TRANSACTIONS = 'GET_CHECKOUT_TRANSACTIONS'

export const GET_UPSELLS = 'GET_UPSELLS'
export const GET_PURCHASE_STATE = 'GET_PURCHASE_STATE'

export const GET_SHOULD_NAVIGATE_TO_DOMAIN = 'GET_SHOULD_NAVIGATE_TO_DOMAIN'

export const SET_LOADER_PENDING = 'SET_LOADER_PENDING'
export const CANCEL_LOADER = 'CANCEL_LOADER'
export const ACTIVATE_LOADER = 'ACTIVATE_LOADER'

export const SUBMIT_DOMAIN_DELAY = 'SUBMIT_DOMAIN_DELAY'

export const GET_PLANS_FROM_GROUP_SKU = 'GET_PLANS_FROM_GROUP_SKU'
export const GET_PLANS_FROM_GROUP_SKU_PENDING = 'GET_PLANS_FROM_GROUP_SKU_PENDING'
export const GET_PLANS_FROM_GROUP_SKU_FULFILLED = 'GET_PLANS_FROM_GROUP_SKU_FULFILLED'
export const GET_PLANS_FROM_GROUP_SKU_ERROR = 'GET_PLANS_FROM_GROUP_SKU_ERROR'

export const GET_DOMAIN_PRICING_FOR_SPECIFIC_DOMAIN = 'GET_DOMAIN_PRICING_FOR_SPECIFIC_DOMAIN'
export const GET_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN = 'GET_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN'
export const SUBMIT_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN = 'SUBMIT_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN'
export const RESET_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN = 'RESET_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN'

export const CHECKOUT_SET_DOMAIN_SEARCH_TERM = 'CHECKOUT_SET_DOMAIN_SEARCH_TERM'
export const CHECKOUT_RESET_DOMAIN_SEARCH = 'CHECKOUT_RESET_DOMAIN_SEARCH'
export const CHECKOUT_GET_AVAILABLE_DOMAINS = 'CHECKOUT_GET_AVAILABLE_DOMAINS'
export const CHECKOUT_GET_AVAILABLE_DOMAINS_PENDING = 'CHECKOUT_GET_AVAILABLE_DOMAINS_PENDING'

export const CHECKOUT_SET_DOMAIN_CONNECT_TERM = 'CHECKOUT_SET_DOMAIN_CONNECT_TERM'
export const CHECKOUT_RESET_DOMAIN_CONNECT = 'CHECKOUT_RESET_DOMAIN_CONNECT'
export const CHECKOUT_GET_DOMAIN = 'CHECKOUT_GET_DOMAIN'

export const POST_CONNECT_DOMAIN_NAME = 'POST_CONNECT_DOMAIN_NAME'
export const POST_CONNECT_DOMAIN_NAME_PENDING = 'POST_CONNECT_DOMAIN_NAME_PENDING'
export const POST_CONNECT_DOMAIN_NAME_ERROR = 'POST_CONNECT_DOMAIN_NAME_ERROR'
export const POST_CONNECT_DOMAIN_NAME_FULFILLED = 'POST_CONNECT_DOMAIN_NAME_FULFILLED'

export const RESET_CONNECT_DOMAIN_NAME = 'RESET_CONNECT_DOMAIN_NAME'
export const SET_CONNECT_DOMAIN_NAME = 'SET_CONNECT_DOMAIN_NAME'

export const SET_CHECKOUT_DOMAIN_ERROR = 'SET_CHECKOUT_DOMAIN_ERROR'
export const RESET_CHECKOUT_DOMAIN_ERROR = 'RESET_CHECKOUT_DOMAIN_ERROR'

export const GET_DOMAIN_INFORMATION = 'GET_DOMAIN_INFORMATION'
export const GET_DOMAIN_INFORMATION_PENDING = 'GET_DOMAIN_INFORMATION_PENDING'
export const GET_DOMAIN_INFORMATION_ERROR = 'GET_DOMAIN_INFORMATION_ERROR'
export const GET_DOMAIN_INFORMATION_FULFILLED = 'GET_DOMAIN_INFORMATION_FULFILLED'

export const SET_POST_TO_PAYPAL = 'SET_POST_TO_PAYPAL'
export const RESET_POST_TO_PAYPAL = 'RESET_POST_TO_PAYPAL'

export const GET_DISCOUNT_DEFINITION = 'GET_DISCOUNT_DEFINITION'
export const GET_DISCOUNT_DEFINITION_RESET = 'GET_DISCOUNT_DEFINITION_RESET'

export const GET_PUBLIC_PLANS = 'GET_PUBLIC_PLANS'
export const CREATE_EMAIL_USER = 'CREATE_EMAIL_USER'
export const SET_PAYPAL = 'SET_PAYPAL'

export const RESET_COUPON_CODE = 'RESET_COUPON_CODE'
export const SET_SELECTED_PAYMENT_TERM = 'SET_SELECTED_PAYMENT_TERM'
export const SET_SELECTED_PLAN_SKU = 'SET_SELECTED_PLAN_SKU'
export const SET_COUPON_CODE = 'SET_COUPON_CODE '
export const GET_PUBLIC_BILLING_INFO = 'GET_PUBLIC_BILLING_INFO'

export const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS'
export const SET_RECAPTCHA_VALUE = 'SET_RECAPTCHA_VALUE'
export const SET_CREDITCARD_DETAILS = 'SET_CREDITCARD_DETAILS'
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS'
export const PURCHASE_IN_PROCESS = 'PURCHASE_IN_PROCESS'

export const SHOW_CREDITCARD_ERROR = 'SHOW_CREDITCARD_ERROR'
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR'
export const SET_CONNECT_DOMAIN = 'SET_CONNECT_DOMAIN'

export const GDPR_OPT_OUT = 'GDPR_OPT_OUT'
