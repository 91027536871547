import React from 'react'

import PricingCard from './../pricing-card.style'

const FeatureList = ({ features, isActive, setSelectedPlan, disabled }) =>
  features.map((feature, index) => (
    <PricingCard.FeaturesList
      key={`feature-list-item-${feature}-${index}`}
      className='list-item'
      onClick={setSelectedPlan}
      isDisabled={disabled}
    >
      {feature === true ? (
        <PricingCard.Done />
      ) : feature === false ? (
        <PricingCard.Close isActive={isActive} />
      ) : (
        feature
      )}
    </PricingCard.FeaturesList>
  ))

export default FeatureList
