import * as constants from './constants'

import * as checkoutConstants from '../checkout-constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

import { snakeToCamelCaseObject } from '@eig-builder/core-utils/helpers/object-helper'

import SchemaSubmitHelper from '@eig-builder/module-property-editor/helpers/schema-submit'

import CheckoutHelpers from '../helpers/checkout-helpers'

import some from 'lodash/some'
import each from 'lodash/each'
import camelCase from 'lodash/camelCase'
import capitalize from 'lodash/capitalize'
import max from 'lodash/max'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import get from 'lodash/get'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'

const convertErrorFromServerToClient = error => {
  const errorObj = { message: error.message }

  const errors = error.payload

  if (errors && errors.length && some(errors, e => e.field)) {
    const invalidFields = {
      address: {}
    }
    each(errors, error => {
      if (error.field) {
        const fieldName = error.field.match(/contact_info.(\w+)/i)
        let field = fieldName ? fieldName[1] : error.field

        if (field) {
          field = camelCase(field)
          invalidFields.address[field] = error.errors[0]
        }
      }
    })
    errorObj.fields = invalidFields
  }
  return errorObj
}
/* remove later, could be of some use in the near future
const convertVDeckErrorToSomethingUsefull = (error) => {
  if (!error.errors) {
    return {}
  }

  const invalidParams = error.errors.filter(i => i.code === 'INVALID_PARAMS')
  if (invalidParams.length) {
    const invalidFields = {
      address: {},
      cc: {}
    }
    each(invalidParams, i => {
      let fieldNameMatch = i.message.match(/payment_info.(\w+)/i)
      if (fieldNameMatch && fieldNameMatch[1]) {
        const nameMap = {
          'method': 'method',
          'card_number': 'card',
          'card_expire_month': 'month',
          'card_expire_year': 'year',
          'csc': 'cvc'
        }

        const translatedField = nameMap[fieldNameMatch[1]]
        invalidFields.cc[translatedField] = i.message
        return
      }

      fieldNameMatch = i.message.match(/address.(\w+)/i)
      if (fieldNameMatch && fieldNameMatch[1]) {
        const field = camelCase(fieldNameMatch[1])
        invalidFields.address[field] = i.message
      }

      if (i.message.indexOf('state ') === 0) {
         catch
          {"errors":[{"code":"INVALID_PARAMS","message":"state () is not a valid two-letter US state or Canadian province code"}]}
          {"errors":[{"code":"INVALID_PARAMS","message":"state (ALABAMA) is not a valid two-letter US state or Canadian province code"}]}
          this is inconsistent with other errors
        if (i.message.indexOf('state ()') >= 0) {
          invalidFields.address['state'] = 'required'
        } else {
          invalidFields.address['state'] = i.message
        }
      }
    })

    return { fields: invalidFields }
  }

  const erroCodeMessageFunc = (errorCode) => {
    const someErrors = error.errors.filter(i => i.code === errorCode)
    if (someErrors.length) {
      return someErrors[0].message
    }
  }

  const generalError = erroCodeMessageFunc('CC_AUTH_FAILED')
  if (generalError) {
    return {
      ccAuthFailed: generalError
    }
  }

  const errorMessage = erroCodeMessageFunc('BAD_CREDENTIALS')
  if (errorMessage) {
    return {
      invalidTonce: errorMessage
    }
  }

  return {}
} */

const emptyTerm = {
  term: checkoutConstants.DEFAULT_PLAN_TERM,
  monthlyPrice: '???',
  price: '???'
}

// make first letter of sentence upper case and the rest not (except for words that are uppercase or the second character is upper)
const firstUpperCase = value => {
  return value
    .split(' ')
    .map((i, index) =>
      i.toUpperCase() === i || (i[1] || '') === (i[1] || '').toUpperCase()
        ? i
        : index > 0
          ? i.toLowerCase()
          : capitalize(i)
    )
    .join(' ')
}

const parseFeatures = feature => {
  return feature
    .trim()
    .split(',')
    .filter(i => i)
    .map(item => {
      return { title: firstUpperCase(item.trim()) }
    })
}

const convertPrices = prices => {
  const result = {}
  each(prices, price => {
    const converted = snakeToCamelCaseObject(price)
    converted.price /= 100
    converted.monthlyPrice /= 100
    result[price.term] = converted
  })
  return result
}

const mapPlans = products => {
  const result = (products || []).map((plan, index) => {
    if (!plan.metadata) {
      plan.metadata = plan.metadata || {}
    }

    // parse features
    const match = (get(plan, 'metadata.features') || '').match(/included:(.*)not_included:(.*)|included:(.*)/i)
    let features = []
    let missingFeatures = []
    if (match) {
      features = parseFeatures(match[1] || match[3])
      missingFeatures = parseFeatures(match[2])
    }

    const metadata = plan.metadata
    const model = {
      title: metadata.marketplace_title || metadata.banner_title || plan.title,
      shopCartTitle: metadata.plan_subtitle || plan.title,
      shortDiscription:
        metadata.marketplace_subtitle ||
        metadata.marketplace_description ||
        metadata.banner_subtitle ||
        plan.description,
      description: plan.description,
      descriptionLong: metadata.marketplace_description,
      mostPopular: metadata.plans_popular,
      selectOrder: metadata.plans_selected_order || 0,
      defaultSelected: false,
      priority: plan.priority,
      sku: plan.sku,
      features: features,
      missingFeatures: missingFeatures,
      upsell_default_selected: metadata.upsell_default_selected,
      prices: convertPrices(plan.pricing),
      plans: metadata.plans,
      image: metadata.banner_icon || metadata.banner || metadata.icon,
      includes: plan.includes,
      isIncludedInCurrentPlan: plan.is_included_in_current_plan || metadata.is_included_in_current_plan, // for cross-sell page
      type: plan.type || ''
    }

    if (model.prices) {
      model.price = model.prices[checkoutConstants.DEFAULT_PLAN_TERM] || emptyTerm

      // get maximum term
      model.maxTerm = model.prices[max(map(model.prices, k => k.term))] || emptyTerm
    }

    return model
  })

  return result
}

const initialState = {
  plans: {},
  plansReceiving: true,
  plansError: null,
  paymentInfoObj: null,
  paymentInfoReceiving: false,
  paymentInfoError: null,
  submitCheckoutFormObj: null,
  submitCheckoutFormReceiving: false,
  submitCheckoutFormError: null,
  groupSkuPlans: [],
  getTonceError: null,
  getUpsellsResponse: null,
  submitDomainFormForSpecificDomainError: {},
  shouldNavigateToDomain: {},

  connectDomainReceiving: false,
  connectDomainError: {},
  connectDomainResponse: {},
  checkoutDomainSearchError: false,

  getDomainInformationReceiving: false,
  getDomainInformationError: null,
  getDomainInformationResponse: null,

  paymentInfoSending: false,

  // start public checkout page
  selectedPlanSku: '',
  selectedPaymentTerm: checkoutConstants.DEFAULT_PLAN_TERM,
  couponCode: ''
  // end public checkout page
}

// set initial coupon code
initialState.couponCode = CheckoutHelpers.getInitialCouponCode()

// Add info to initial state
const billingInfoCacheKey = 'checkout_billing_info'
try {
  if (window.localStorage && window.localStorage.getItem) {
    const val = window.localStorage.getItem(billingInfoCacheKey)
    const obj = JSON.parse(val)

    const now = new Date().getTime()

    if (obj && obj.time && now - obj.time < 1000 * 60 * 60) {
      initialState.billingFirstName = obj.billingFirstName
      initialState.billingLastName = obj.billingLastName
      initialState.billingCompany = obj.billingCompany
      initialState.billingAddress1 = obj.billingAddress1
      initialState.billingCity = obj.billingCity
      initialState.billingPostal = obj.billingPostal
      initialState.billingCountry = obj.billingCountry
      initialState.billingState = obj.billingState
      initialState.billingPhone = obj.billingPhone
    }
  }
} catch (ex) {
  // dont care, it was only here to help the user
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.POST_CONNECT_DOMAIN_NAME_ERROR:
      return {
        ...state,
        connectDomainReceiving: false,
        connectDomainError: action.body,
        hasPurchaseInProcess: false
      }

    case constants.POST_CONNECT_DOMAIN_NAME_PENDING:
      return {
        ...state,
        connectDomainReceiving: true,
        connectDomainError: null
      }

    case constants.POST_CONNECT_DOMAIN_NAME_FULFILLED:
      return {
        ...state,
        connectDomainReceiving: false,
        connectDomainResponse: action.body
      }

    case constants.GET_DOMAIN_INFORMATION_ERROR:
      return {
        ...state,
        getDomainInformationReceiving: false,
        getDomainInformationError: action.body
      }

    case constants.GET_DOMAIN_INFORMATION_PENDING:
      return {
        ...state,
        getDomainInformationReceiving: true,
        getDomainInformationError: null
      }

    case constants.GET_DOMAIN_INFORMATION_FULFILLED:
      return {
        ...state,
        getDomainInformationReceiving: false,
        getDomainInformationResponse: action.body
      }

    case constants.GET_OFFERS_PENDING:
      return {
        ...state,
        plansReceiving: true,
        plansError: null
      }
    case constants.GET_OFFERS_FULFILLED:
      const data = action.body[0]
      const plans = {}
      if (data) {
        plans.description = data.description
        plans.title = data.title
        plans.plans = sortBy(mapPlans(data.products), plan => plan.priority)
      }
      return {
        ...state,
        plansReceiving: false,
        plans: plans
      }
    case constants.GET_OFFERS_ERROR:
      return {
        ...state,
        plansReceiving: false,
        plansError: action.body
      }
    case constants.GET_PAYMENT_INFO_PENDING:
      return {
        ...state,
        paymentInfoReceiving: true,
        paymentInfoError: null,
        paymentInfoObj: null
      }
    case constants.GET_PAYMENT_INFO_FULFILLED: {
      const newBillingData = {
        billingFirstName: get(action.body, 'contactInfo.firstName'),
        billingLastName: get(action.body, 'contactInfo.lastName'),
        billingCompany: get(action.body, 'contactInfo.organization'),
        billingAddress1: get(action.body, 'contactInfo.address1'),
        billingCity: get(action.body, 'contactInfo.city'),
        billingPostal: get(action.body, 'contactInfo.postalCode'),
        billingCountry: get(action.body, 'contactInfo.country'),
        billingState: get(action.body, 'contactInfo.state'),
        billingPhone: get(action.body, 'contactInfo.phone')
      }

      let newState = null

      if (newBillingData.billingAddress1 && newBillingData.billingCity && newBillingData.billingCountry) {
      // Overwrite all values in the state when we are sure that the payment info has an address available
        newState = {
          ...state,
          ...newBillingData
        }
      } else {
      // Otherwise use the values, but overwrite it with the values which are already saved in the state
        newState = {
          ...newBillingData,
          ...state
        }
      }

      newState.paymentInfoReceiving = false
      newState.paymentInfoObj = action.body
      newState.paymentIsPaypal = get(action.body, 'paymentMethod') === 'paypal'
      return newState
    }
    case constants.GET_PAYMENT_INFO_ERROR:
      return {
        ...state,
        paymentInfoReceiving: false,
        paymentInfoError: action.body,
        hasPurchaseInProcess: false
      }
    case constants.SET_LOADER_PENDING:
      return {
        ...state,
        loaderPending: true,
        submitCheckoutFormReceiving: true
      }
    case constants.CANCEL_LOADER:
      return {
        ...state,
        loaderPending: false,
        waitingForCreateOrder: false,
        submitCheckoutFormReceiving: false
      }
    case constants.ACTIVATE_LOADER:
      const newState3 = { ...state }
      if (newState3.loaderPending) {
        newState3.waitingForCreateOrder = true
      }
      return newState3
    case constants.SUBMIT_DOMAIN_DELAY:
      const newState4 = { ...state }
      if (newState4.submitDomainFormForSpecificDomainReceiving) {
        newState4.delayedWaitingForDomainSubmit = true
      }
      return newState4
    case constants.SUBMIT_FORM_PENDING:
      return {
        ...state,
        submitCheckoutFormReceiving: true,
        submitCheckoutFormError: null,
        submitCheckoutFormObj: null
      }
    case constants.SUBMIT_FORM_FULFILLED:
      const resultState = {
        ...state,
        submitCheckoutFormReceiving: false,
        loaderPending: false
      }

    /*
      Unknown = 0
      OrderSuccess = 1
      OrderFailed = 2
      CreditCardInvalid = 3
      */
      const OrderFailed = 2
      const CreditCardInvalid = 3

      if (
        action.body &&
        (action.body.result_status === CreditCardInvalid || action.body.result_status === OrderFailed)
      ) {
        let getNewTonce = true

        resultState.submitCheckoutFormError = {
          ccAuthFailed: action.body.result_status === CreditCardInvalid ? action.body.error_message || ' ' : null,
          errorFromServer: action.body.error_message
        }

        if (action.body.result_status === OrderFailed) {
          if (action.extraArguments && action.extraArguments.paymentMethod !== 'credit_card') {
            getNewTonce = false
          // the server has this payment method registerd for this customer in this case, front-end needs to know
            resultState.lockPaymentMethod = action.extraArguments.paymentMethod
          }
        }

        if (getNewTonce) {
          delete resultState.getTonceError
          delete resultState.getTonceResponse
        }
      } else {
        resultState.submitCheckoutFormObj = action.body
      }

      return resultState
    case constants.SUBMIT_FORM_ERROR:
    // billing/create_order call fails
      const errorObj = convertErrorFromServerToClient(action.body)
      const state2 = {
        ...state,
        submitCheckoutFormReceiving: false,
        waitingForCreateOrder: false,
        loaderPending: false,
        submitCheckoutFormError: errorObj,
        hasPurchaseInProcess: false,
      // reset purchase state on new flow
        postCreditCardResponse: undefined
      }
      delete state2.getTonceError
      delete state2.getTonceResponse

      return state2
    case constants.RESET_FORM_STATE:
      return {
        ...state,
        initialState
      }
    case constants.GET_PLANS_FROM_GROUP_SKU_PENDING:
      return {
        ...state,
        groupSkuPlansReceiving: true,
        groupSkuPlansError: null
      }
    case constants.GET_PLANS_FROM_GROUP_SKU_FULFILLED:
      const groupSkus = action.body.products
      groupSkus.map(addon => {
        const mappedData = (addon.mappedData = {
          description: addon.description,
          title: addon.title,
          image: '',
          features: []
        })

        if (addon.metadata) {
          const metadata = addon.metadata
          mappedData.description =
            metadata.marketplace_subtitle ||
            metadata.marketplace_description ||
            metadata.banner_subtitle ||
            addon.description
          mappedData.title = metadata.marketplace_title || metadata.banner_title || addon.title
          mappedData.image = encodeURI(metadata.marketplace_big_image) || ''
          mappedData.features = (metadata.features || '').split(',').map(item => {
            return {
              title: item.trim()
            }
          })
        }
        addon.key = addon.sku.toLowerCase()
        addon.features = mappedData.features
        addon.price = { monthlyPrice: addon.price / 100 }
        addon.discount = undefined
        return addon
      })
      return {
        ...state,
        groupSkuPlansReceiving: false,
        groupSkuPlans: groupSkus,
        groupSku: action.extraArguments.sku
      }
    case constants.GET_PLANS_FROM_GROUP_SKU_ERROR:
      return {
        ...state,
        groupSkuPlansReceiving: false,
        groupSkuPlansError: action.body
      }
    case constants.TO_NEXT_FORM:
      return {
        ...state,
        submitCheckoutFormObj: null,
        submitCheckoutFormReceiving: false,
        waitingForCreateOrder: false,
        loaderPending: false,
        delayedWaitingForDomainSubmit: false,
        submitCheckoutFormError: null
      }
    case constants.RESET_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN:
      const newState = { ...state }
      SchemaSubmitHelper.resetSubmitStates(newState, constants.GET_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN)
      return newState
    case constants.CHECKOUT_RESET_DOMAIN_SEARCH:
      return {
        ...state,
        checkoutSetDomainSearchTerm: '',
        checkoutGetAvailableDomainsResponse: [],
        checkoutGetAvailableDomainsExtraArgs: {}
      }
    case constants.CHECKOUT_SET_DOMAIN_SEARCH_TERM:
      return {
        ...state,
        checkoutSetDomainSearchTerm: action.body
      }
    case constants.RESET_SAVE_RESPONSE:
      return {
        ...state,
        postCreditCardResponse: null
      }

    case constants.SET_CHECKOUT_DOMAIN_ERROR:
      return {
        ...state,
        checkoutDomainSearchError: true
      }
    case constants.RESET_CHECKOUT_DOMAIN_ERROR:
      return {
        ...state,
        checkoutDomainSearchError: false
      }

    case constants.SET_POST_TO_PAYPAL:
      return {
        ...state,
        paymentInfoSending: true
      }
    case constants.RESET_POST_TO_PAYPAL:
      return {
        ...state,
        paymentInfoSending: false
      }
    case constants.SET_SELECTED_PLAN_SKU:
      return {
        ...state,
        selectedPlanSku: action.body.planSku
      }
    case constants.SET_SELECTED_PAYMENT_TERM:
      return {
        ...state,
        selectedPaymentTerm: action.body.paymentTerm
      }
    case constants.SET_COUPON_CODE:
      return {
        ...state,
        couponCode: action.body.couponCode
      }
    case constants.RESET_COUPON_CODE: {
      return {
        ...state,
        couponCode: CheckoutHelpers.getInitialCouponCode()
      }
    }
    case constants.SET_ACCOUNT_DETAILS:
      return {
        ...state,
        accountEmail: action.body.email,
        accountPass: action.body.pass
      }
    case constants.SET_RECAPTCHA_VALUE:
      return {
        ...state,
        recaptchaValue: action.body.recaptchaValue
      }
    case constants.SET_PAYPAL:
      return {
        ...state,
        paymentIsPaypal: !!action.body
      }
    case constants.SET_CREDITCARD_DETAILS:
      const newPaymentDetails = {
        ...state,
        [action.body.fieldId]: action.body.value
      }
      newPaymentDetails.isCreditCardNumberUpdated = true

      return newPaymentDetails
    case constants.SET_BILLING_ADDRESS:
      const newBillingAddressState = {
        ...state,
        [action.body.fieldId]: action.body.value
      }
    // this will be used when you go to a social provider, we will pre-fill the values
      if (window.localStorage && window.localStorage.setItem) {
        const cacheObj = {
          time: new Date().getTime(),
          ...pick(newBillingAddressState, [
            'billingFirstName',
            'billingLastName',
            'billingCompany',
            'billingAddress1',
            'billingCity',
            'billingPostal',
            'billingCountry',
            'billingState',
            'billingPhone'
          ])
        }
        window.localStorage.setItem(billingInfoCacheKey, JSON.stringify(cacheObj))
      }

      return newBillingAddressState

    case constants.PURCHASE_IN_PROCESS:
      return {
        ...state,
        hasPurchaseInProcess: action.body === true
      }
    case constants.SHOW_CREDITCARD_ERROR:
      const newState1 = {
        ...state,
        hasCreditCardError: action.body === true
      }
      newState1.isCreditCardNumberUpdated = false
      return newState1
    case constants.GET_CURRENT_USER_ERROR:
      return {
        ...state,
        hasPurchaseInProcess: false
      }
    case constants.SET_CONNECT_DOMAIN:
      return {
        ...state,
        connectedDomain: action.body
      }
  }

  return (
    ReducerHelper.listenToFetchActions(state, action, constants.GET_TONCE, {}) ||
    ReducerHelper.listenToFetchActions(state, action, constants.POST_CREDIT_CARD, {
      retrieving: postCreditcardState => {
        postCreditcardState.startingCCAuthCallTimeStamp = CheckoutHelpers.getTimeStamp()
        postCreditcardState.paymentInfoSending = true
        postCreditcardState.hasInvalidZip = undefined
      },
      response: body => body,
      errorKey: 'submitCheckoutFormError',
      error: (error2, newState) => {
        newState.loaderPending = false
        newState.waitingForCreateOrder = false
        newState.submitCheckoutFormReceiving = false
        newState.paymentInfoSending = false
        newState.hasPurchaseInProcess = false
        if ((get(error2, 'message') || '').indexOf('zip') !== -1) {
          newState.hasInvalidZip = get(error2, 'message')
        }
        return error2
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_UPSELLS, {
      response: body => mapPlans(body)
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CHECKOUT_TRANSACTIONS, {
      // order by product type so that the website plans are first
      response: body => sortBy(body, i => i.productType)
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_DOMAIN_PRICING_FOR_SPECIFIC_DOMAIN, {}) ||
    ReducerHelper.listen(state, action, constants.GET_CURRENT_CC, 'currentCC') ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN, {
      response: (body, newDomainState) => {
        newDomainState.delayedWaitingForDomainSubmit = false
        return body
      },
      error: (error, newDomainState) => {
        newDomainState.delayedWaitingForDomainSubmit = false
        return error
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PURCHASE_STATE, {
      response: (body, newState24) => {
        newState24.purchaseStateId = body.id
        return body
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_DOMAIN_FORM_FOR_SPECIFIC_DOMAIN, {
      retrievingKey: 'submitDomainFormForSpecificDomainReceiving',
      response: (body, newDomainState) => {
        newDomainState.delayedWaitingForDomainSubmit = false
        return body
      },
      error: (error, newDomainState) => {
        newDomainState.delayedWaitingForDomainSubmit = false
        return error
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_CONTACT_INFO, {
      errorKey: 'submitCheckoutFormError',
      error: (error, newState) => {
        delete newState.getTonceError
        delete newState.getTonceResponse
        return convertErrorFromServerToClient(error)
      },
      response: body => true
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_DISCOUNT_DEFINITION) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PUBLIC_BILLING_INFO, {
      response: (body, newState) => {
        // Update the state with the country code. Only if we don't already have a valu
        // Could be already filled in by the localStorage value
        if (isEmpty(newState.billingCountry)) {
          newState.billingCountry = get(body, 'countryCode')
        }
        return body
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PUBLIC_PLANS, {
      response: body => {
        const data = action.body[0]
        return sortBy(mapPlans(data.products), plan => plan.priority)
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.CREATE_EMAIL_USER, {
      error: (error, newState) => {
        newState.hasPurchaseInProcess = false
        newState.recaptchaValue = null
        return error
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.CHECKOUT_SET_DOMAIN_SEARCH_TERM, {}) ||
    ReducerHelper.listen(state, action, constants.GET_SHOULD_NAVIGATE_TO_DOMAIN, 'shouldNavigateToDomain', {
      triggerRetrieving: true,
      response: body => {
        if (body === true) {
          return {
            should_navigate: true
          }
        }
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.CHECKOUT_GET_AVAILABLE_DOMAINS, {
      response: (body, newState, action) => {
        const serverResponse = body
        let prevItems = newState.checkoutGetAvailableDomainsResponse || []

        // reset to new list, flush the old values
        if (action.frame === 1) {
          prevItems = []
        }

        const newItems = map(prevItems, oldRecord => {
          // Get More information about a domain
          const additionalInformation = find(serverResponse, newRecord => oldRecord.domain === newRecord.domain)
          if (additionalInformation) {
            // tag as used. other wise it will add it twise to the same list
            additionalInformation.used = true

            return {
              ...oldRecord,
              ...additionalInformation
            }
          }
          return oldRecord
        })

        each(serverResponse, domain => {
          if (action.frame === 1 && !domain.used) {
            newItems.push(domain)
          }
        })

        return newItems
      }
    }) ||
    state
  )
}

export default reducer
