import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
// the default price term that is selected
const brand = getRuntimeConfig()._brand
const SaleActive = true // ! Temp-Hack for feature flag (checkout is not part of appshell and wont use dynamic runtime config)
export const DEFAULT_PLAN_TERM = (brand === 'websitebuilder' || brand === 'constantcontact')
  ? 1
  : 12 * 2

export const AFFILIATE_CODE = 'h1asbdyw5q-plan-50' // black friday/holiday discount
export const SITEBUILDER_50_CODE = 'DISCOUNT50'
export const SITEBUILDER_60_CODE = 'DISCOUNT60'
export const WEBSITEBUILDER_60_CODE = 'DISCOUNT60'
export const DEFAULT_GATOR_COUPON = 'GATOR'
export const DEFAULT_CREATIVEMAIL_COUPON = SaleActive ? 'oneforthree' : '1FOR12'
export const DEFAULT_DOMAIN_PRICE_AFTER_CANCELLATION = 16
export const DEFAULT_DOMAIN_PRICE_AFTER_CANCELLATION_GATOR = 17.99

export const SESSION_STORAGE_KEY_COUPON_CODE = 'onboarding_coupon_code'
export const SESSION_STORAGE_KEY_COUPON_CODE_DESCRIPTION = 'onboarding_coupon_code_description'
export const SITEBUILDER_DEFAULT_CODE = SITEBUILDER_60_CODE
export const WEBSITEBUILDER_DEFAULT_CODE = WEBSITEBUILDER_60_CODE

export const SITEBUILDER_DEFAULT_DISCOUNT_PERCENT = 60
export const WEBSITEBUILDER_DEFAULT_DISCOUNT_PERCENT = 60
