import React from 'react'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import find from 'lodash/find'
import set from 'lodash/set'
import each from 'lodash/each'
import filter from 'lodash/filter'
import Text from '@eig-builder/module-localization'
import * as checkoutConstants from '../../../../src/modules/checkout/checkout-constants'

import './lang'

// Do Not export this variable. We always must use this utility. otherwise we wont track analytics events
const SESSION_STORAGE_KEY_CART_ITEMS = 'builder_cart_items'
const SESSION_STORAGE_KEY_LIMITATION_KEY = 'builder_cart_limitation_key'
const SESSION_STORAGE_KEY_ORIGIN = 'builder_cart_origin'

function convertCartItemToAnalyticsProduct (item) {
  return {
    productId: get(item, 'sku'),
    productName: get(item, 'extra_info.frontend_display_name') || get(item, 'sku'),
    productTerm: get(item, 'selected_term'),
    productPrice: get(item, 'extra_info.frontend_price'),
    productQuantity: 1
  }
}

export function setAnalyticsForItem (item, step, paymentMethod) {
  const variantString = 'term=' + item.selected_term

  let event = 'checkout'
  if (step === 3) {
    event = 'transaction'
  }

  if (item.sku === 'DOMAIN') {
    window.dataLayer.push({
      event,
      ecommerce: {
        checkout: {
          actionField: {
            step,
            option: paymentMethod
          },
          products: [{
            name: item.extra_info.site_name + item.extra_info.tld,
            id: item.extra_info.frontend_id,
            price: item.extra_info.frontend_price,
            brand: '',
            category: 'DOMAIN',
            variant: variantString,
            quantity: 1
          }]
        }
      }
    })
  }
  if (item.sku.match(/^PLAN*/)) {
    window.dataLayer.push({
      event,
      ecommerce: {
        checkout: {
          actionField: {
            step
          },
          products: [{
            name: item.extra_info.frontend_display_name,
            id: item.extra_info.frontend_id,
            price: item.extra_info.frontend_price,
            brand: '',
            category: 'PLAN',
            variant: variantString,
            quantity: 1
          }]
        }
      }
    })
  }
}

let updateListener = null

export function getCartItems () {
  try {
    const cartItems = window.sessionStorage.getItem(SESSION_STORAGE_KEY_CART_ITEMS)
    return JSON.parse(cartItems) || []
  } catch (ex) {
    console.error('invalid session storage content')
    return []
  }
}

export function setCartItems (analyticsService, items) {
  if (isEmpty(items)) {
    console.error('items can not be empty')
  }

  if (!analyticsService) {
    console.error('analyticsService can not be empty')
  }

  analyticsService.trackClearCart()
  // set frontend id. makes it easy to modify and delete
  each(items, (item, i) => {
    set(item, 'extra_info.frontend_id', new Date().getTime() + i)
    // track analytics
    analyticsService.trackAddToCart(convertCartItemToAnalyticsProduct(item))
    if (window.dataLayer) {
      setAnalyticsForItem(item, 1, '')
    }
  })

  window.sessionStorage.setItem(SESSION_STORAGE_KEY_CART_ITEMS, JSON.stringify(items))

  // update listener
  if (updateListener) {
    updateListener(items)
  }
}

// start coupon
export function setCouponCode (code) {
  if (code) {
    window.sessionStorage.setItem(checkoutConstants.SESSION_STORAGE_KEY_COUPON_CODE, code)
  }
}

export function getCouponCode () {
  return window.sessionStorage.getItem(checkoutConstants.SESSION_STORAGE_KEY_COUPON_CODE)
}

export function setCouponDefinition (definition) {
  if (definition) {
    if (typeof definition === 'object') {
      definition = JSON.stringify(definition)
    }
    window.sessionStorage.setItem(checkoutConstants.SESSION_STORAGE_KEY_COUPON_CODE_DESCRIPTION, definition)
  }
}

export function getCouponDescription () {
  const couponCode = window.sessionStorage.getItem(checkoutConstants.SESSION_STORAGE_KEY_COUPON_CODE_DESCRIPTION)
  if (couponCode?.indexOf('{') >= 0) {
    const couponDescription = JSON.parse(couponCode)
    return couponDescription?.description || null
  }

  return null
}

export function resetCouponCode (codeOnly = false) {
  window.sessionStorage.removeItem(checkoutConstants.SESSION_STORAGE_KEY_COUPON_CODE)
  if (!codeOnly) window.sessionStorage.removeItem(checkoutConstants.SESSION_STORAGE_KEY_COUPON_CODE_DESCRIPTION)
}
// end coupon

// start limitation
export function setLimitationKey (val) {
  if (val) {
    window.sessionStorage.setItem(SESSION_STORAGE_KEY_LIMITATION_KEY, val)
  }
}

export function getLimitationKey () {
  return window.sessionStorage.getItem(SESSION_STORAGE_KEY_LIMITATION_KEY)
}

export function resetLimitationKey () {
  window.sessionStorage.removeItem(SESSION_STORAGE_KEY_LIMITATION_KEY)
}
// end limitation

// start orgin
export function setPurchaseOrigin (val) {
  if (val) {
    window.sessionStorage.setItem(SESSION_STORAGE_KEY_ORIGIN, val)
  }
}

export function getPurchaseOrigin () {
  return window.sessionStorage.getItem(SESSION_STORAGE_KEY_ORIGIN)
}

export function resetPurchaseOrigin () {
  window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ORIGIN)
}
// end orgin

export function addItemToCartItems (analyticsService, item) {
  if (isEmpty(item)) {
    console.error('items can not be empty')
  }

  if (!analyticsService) {
    console.error('analyticsService can not be empty')
  }

  // set id in the item extra_info
  set(item, 'extra_info.frontend_id', new Date().getTime())

  const items = getCartItems()
  items.push(item)
  window.sessionStorage.setItem(SESSION_STORAGE_KEY_CART_ITEMS, JSON.stringify(items))

  // track analytics
  analyticsService.trackAddToCart(convertCartItemToAnalyticsProduct(item))
  if (window.dataLayer) {
    setAnalyticsForItem(item, 1, '')
  }

  // update listener
  if (updateListener) {
    updateListener(items)
  }
}

export function clearCartItems (analyticsService) {
  if (!analyticsService) {
    console.error('analyticsService can not be empty')
  }

  window.sessionStorage.removeItem(SESSION_STORAGE_KEY_CART_ITEMS)

  // track analytics
  analyticsService.trackClearCart()

  // update listener
  if (updateListener) {
    updateListener([])
  }
}

// This is a temp hack. this util should be moved to the redux store
export function subscribeToUpdates (callback) {
  updateListener = callback
}
export function unSubscribeToUpdates (callback) {
  if (callback === updateListener) {
    updateListener = null
  }
}
// Supporting Functions
export function getTotalCostOfCart (items) {
  let totalCost = 0
  if (!items) {
    items = getCartItems()
  }
  for (const i in items) {
    if (items[i].sku.indexOf('PLAN') > -1) {
      const plan = find(items[i].available_plans, availablePlan => availablePlan.sku === items[i].selected_plan)
      const costObj = find(plan.available_terms, term => term.term === items[i].selected_term)
      totalCost = totalCost + costObj.price
    } else {
      for (const j in items[i].available_terms) {
        if (items[i].available_terms[j].term === items[i].selected_term) {
          totalCost = totalCost + items[i].available_terms[j].price
        }
      }
    }
  }
  return totalCost
}
export function hasDomainItems () {
  return some(getCartItems(), item => item.sku === 'DOMAIN')
}

export function getDomainItems () {
  return filter(getCartItems(), item => item.sku === 'DOMAIN')
}

export function removeDomainItem (removeDomain) {
  const currItems = getDomainItems()
  const newItems = currItems.filter((item) => {
    const domain = item.extra_info.site_name + item.extra_info.tld
    return (removeDomain !== domain)
  })
  window.sessionStorage.setItem(SESSION_STORAGE_KEY_CART_ITEMS, JSON.stringify(newItems))
}

export function getSelectedPlan (item) {
  const selectedPlan = item.selected_plan
  if (!isEmpty(selectedPlan)) {
    // is a site. find the terms
    return find(item.available_plans, availablePlan => availablePlan.sku === selectedPlan)
  }
  return null
}

export function getAvailableTerms (item) {
  const selectedPlanSku = item.selected_plan

  // domains and apps only have terms. but sites have plans
  let availableTerms = item.available_terms
  if (!isEmpty(selectedPlanSku)) {
    // is a site. find the terms
    const selectedPlan = getSelectedPlan(item)
    availableTerms = get(selectedPlan, 'available_terms')
  }
  return availableTerms
}

export function getSelectedAvailableTerm (item) {
  const selectedTerm = item.selected_term
  return find(getAvailableTerms(item), availableTerm => availableTerm.term === selectedTerm)
}

export function getPriceFromItem (item) {
  return get(getSelectedAvailableTerm(item), 'price', 0)
}

export function getProratedCreditFromItem (item) {
  return get(getSelectedAvailableTerm(item), 'pro_rated_credit', 0)
}

export function getDiscountFromItem (item) {
  return get(getSelectedAvailableTerm(item), 'discount', 0)
}

export function getUnitPriceFromItem (item) {
  return get(getSelectedAvailableTerm(item), 'unit_price', 0)
}

export function getTitleFromItem (item) {
  // todo later support apps
  if (item.sku === 'DOMAIN') {
    return getDomainNameFromItem(item)
  } else {
    if (!isEmpty(item.extra_info)) {
      // looks like a site
      const selectedPlan = getSelectedPlan(item)
      let title = `${get(selectedPlan, 'display_name', get(item, 'extra_info.frontend_display_name', ''))}`
      if (!title) {
        title = item.display_name
      }
      return title
    }
    return `TODO SUPPORT APPS - ${item.sku}`
  }
}

export function getSubTitleFromItem (item) {
  if (item.sku === 'DOMAIN') {
    if (item.show_domain_protection) {
      return <Text message='core.cart.utility.domainSubTitleWithPrivacy' />
    } else {
      return <Text message='core.cart.utility.domainSubTitleWithoutPrivacy' />
    }
  } else {
    return ''
  }
}

export function getDomainNameFromItem (item) {
  const siteName = getSiteNameFromItem(item)
  const tld = getTLDFromItem(item)
  return `${siteName}${tld}`
}

export function getSiteNameFromItem (item) {
  return get(item, 'extra_info.site_name', '')
}

export function getTLDFromItem (item) {
  return get(item, 'extra_info.tld', '')
}

// Utility Methods for Session PaymentMethod and Billing when selecting PayPal

export function getCheckoutPaymentMethod () {
  try {
    const checkoutPaymentMethod = window.sessionStorage.getItem('CHECKOUT_PAYMENT_METHOD')
    return checkoutPaymentMethod || ''
  } catch (ex) {
    console.error('invalid session storage content')
    return ''
  }
}

export function getCheckoutAddressInfo () {
  try {
    const addressInfo = window.sessionStorage.getItem('CHECKOUT_ADDRESS_INFO')
    return JSON.parse(addressInfo) || {}
  } catch (ex) {
    console.error('invalid session storage content')
    return {}
  }
}

export function setCheckoutPaymentMethod (value) {
  window.sessionStorage.setItem('CHECKOUT_PAYMENT_METHOD', value)
  if (window.dataLayer) {
    const items = getCartItems()
    each(items, (item, i) => {
      setAnalyticsForItem(item, 2, value)
    })
  }
}

export function setCheckoutAddressInfo (value) {
  window.sessionStorage.setItem('CHECKOUT_ADDRESS_INFO', JSON.stringify(value))
}

export function removeCheckoutPaymentMethod () {
  window.sessionStorage.removeItem('CHECKOUT_PAYMENT_METHOD')
}

export function getMonthlyPrice (value) {
  const term = get(value, 'selected_term') || 1
  const unitPrice = getUnitPriceFromItem(value)
  return unitPrice / term
}

export function getSelectedTerm (value) {
  return get(value, 'selected_term') || 1
}

export function getYearlyDomainPrice (value) {
  const obj = find(value.available_terms, term => term.term === value.selected_term)
  let yearlyPrice = 0
  if (obj) {
    yearlyPrice = ((obj.unit_price / 100) / obj.term) * 12
  } else {
    yearlyPrice = value.price / 100
  }
  return yearlyPrice
}
