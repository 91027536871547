import Loader from 'core/components/centred-page-loader'
import Loadable from 'react-loadable'

import * as pageTypes from '@eig-builder/core-utils/analytics/page-types'
import * as flowTypes from '@eig-builder/core-utils/analytics/flow-types'

import ComparePlansModal from '../pages/compare-plans-modal'
import UpgradeFreePlanModal from '../pages/upgrade-free-plan-modal'

const Routes = [
  {
    pattern: '/upgrade-modal',
    pageTitle: 'Upgrade Modal',
    component: Loadable({
      loader: () => import('../pages/upgrade-modal'),
      loading: Loader
    }),
    isPublic: true,
    pageType: pageTypes.CHECKOUT,
    flowType: flowTypes.NONE
  }, {
    pattern: '/compare-plans-modal',
    pageTitle: 'Plans Modal',
    component: ComparePlansModal,
    isPublic: true,
    pageType: pageTypes.CHECKOUT,
    flowType: flowTypes.NONE
  }, {
    pattern: '/free-to-paid-plans-modal',
    pageTitle: 'Free To Paid Modal',
    component: UpgradeFreePlanModal,
    isPublic: true,
    pageType: pageTypes.CHECKOUT,
    flowType: flowTypes.NONE
  },
  {
    pattern: '/partner-upgrade-modal',
    pageTitle: 'Partner Upgrade Modal',
    component: Loadable({
      loader: () => import('../pages/partner-upgrade-modal'),
      loading: Loader
    }),
    isPublic: true,
    pageType: pageTypes.CHECKOUT,
    flowType: flowTypes.NONE
  }
]

export default Routes
