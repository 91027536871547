import { getQueryParametersFromUrl, getBaseUrl, siteNames } from '@eig-builder/core-utils/helpers/url-helper'

import has from 'lodash/has'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { TrackAnEcomEventForOrderId } from '@eig-builder/core-utils/analytics/helpers/ecommerce-event-helper'

const getResourceId = (match, location) => {
  const prams = getQueryParametersFromUrl(location.search)
  const resourceId =
    get(prams, 'resource_id') ||
    get(prams, 'resourceId') ||
    get(prams, 'resource_Id') ||
    get(match.params, 'resourceId')
  return !isEmpty(resourceId) ? (resourceId === 'null' ? null : resourceId) : null
}

// navigate to the next part of the flow
// the return url is passed and tranasations that are done are being concated
const mergeUrl = (history, newUrl, extraParams) => {
  let param
  if (history.location.search) {
    param = getQueryParametersFromUrl(history.location.search, true)
  }

  if (param || extraParams) {
    newUrl +=
      '?' +
      getParam({
        ...param,
        ...extraParams
      })
  }

  return newUrl
}

function getParam (obj) {
  let result = ''
  for (const key in obj) {
    result += `${key}=${encodeURIComponent(obj[key])}&`
  }
  return result.substr(0, result.length - 1)
}

// concat part to url, include resource Id in path if we have a resource id
const makeUrl = (match, location, history, path1, path2, params) => {
  const resource = getResourceId(match, location)

  if (resource) {
    return mergeUrl(history, `/${path1}/${resource}/${path2}`, params)
  }

  if (!path2) {
    return mergeUrl(history, `/${path1}`, params)
  }

  return mergeUrl(history, `/${path1}/${path2}`, params)
}

class CheckoutUrlFlow {
  static getResourceId (match, location) {
    return getResourceId(match, location)
  }

  static updateQueriesInUrlWithoutRefresh (history, location, callback) {
    const newPath = location.pathname
    let queryStrings = {}
    if (location.search) {
      queryStrings = getQueryParametersFromUrl(location.search, true)
    }
    callback(queryStrings)

    // replace without refresh, but for the next page keep it in the url
    history.replace(`${newPath}?${getParam(queryStrings)}`)
  }

  static setReturnUrlInUrlWithoutRefresh (history, location, returnUrl) {
    CheckoutUrlFlow.updateQueriesInUrlWithoutRefresh(history, location, queryStrings => {
      queryStrings.returnurl ? (queryStrings.returnurl = returnUrl) : (queryStrings.returnUrl = returnUrl)
    })
  }

  static setPurchaseIdInUrlWithoutRefresh (history, location, psid) {
    CheckoutUrlFlow.updateQueriesInUrlWithoutRefresh(history, location, queryStrings => {
      queryStrings.psid = psid
    })
  }

  static setSuccessInUrlWithoutRefresh (history, location, successUrl) {
    CheckoutUrlFlow.updateQueriesInUrlWithoutRefresh(history, location, queryStrings => {
      queryStrings.success = successUrl
    })
  }

  static setTransferDomain (history, location, domain) {
    CheckoutUrlFlow.updateQueriesInUrlWithoutRefresh(history, location, queryStrings => {
      queryStrings.domain = domain
      queryStrings.trans = true
      queryStrings.local_d = true
    })
  }

  static setNewDomain (history, location, domain) {
    CheckoutUrlFlow.updateQueriesInUrlWithoutRefresh(history, location, queryStrings => {
      queryStrings.domain = domain
      queryStrings.trans = undefined
      queryStrings.local_d = true
    })
  }

  static setDomainFlag (history, location) {
    CheckoutUrlFlow.updateQueriesInUrlWithoutRefresh(history, location, queryStrings => {
      queryStrings.trans = false
      queryStrings.local_d = true
    })
  }

  static getPurchaseStateId (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return param.psid
    }
  }

  static getHidePlansFlag (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return has(param, 'hideplans')
    }
  }

  static getHideTermsFlag (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return has(param, 'hideterms')
    }
  }

  static getSmallPlansFlag (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return has(param, 'smallplans')
    }
  }

  static getReturnUrl (location) {
    if (location.search) {
      const param = getQueryParametersFromUrl(location.search, true)
      return param.returnurl
    }
  }

  static getSiteId (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return param['site-id'] || null
    }
  }

  static getShowDomainSearch (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return has(param, 'domainsearch')
    }
  }

  static hideTitlesPackageChoose (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return has(param, 'smallplans') && window.location.search.includes('PLAN')
    }
  }

  static showNewTitles (context) {
    return true
    // if (history.location.search) {
    //   const param = getQueryParametersFromUrl(history.location.search, true)
    //   return has(param, 'domainsearch') && has(param, 'smallplans')
    // }
  }

  static getSku (match, history) {
    const sku = match.params ? match.params.sku : ''
    if (!isEmpty(sku)) {
      return sku
    } else if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return has(param, 'sku') ? param.sku : undefined
    }
    return undefined
  }

  // TODO: Verify if sku and skus is the same. If same, delete this function.
  static getSkus (match, history) {
    const sku = match ? match.params.sku : ''
    if (!isEmpty(sku)) {
      return sku
    } else if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return has(param, 'skus') ? param.skus : undefined
    }
    return undefined
  }

  static getCouponCode (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return param.coupon
    }
  }

  static getTerm (match, history) {
    if (match && has(match.params, 'term')) {
      return parseInt(match.params.term)
    } else if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)
      return has(param, 'term') ? ~~parseInt(param.term) : 0
    }
    return 0
  }

  static getPlan (match, history, plans) {
    const sku = CheckoutUrlFlow.getSku(match, history)

    if (!plans || !plans.plans) {
      return {}
    }

    return plans.plans.filter(i => i.sku === sku)[0]
  }

  // returns the domain name, we have a domain name if checkout is called from the 'buy a domain' functionality
  static getDomainName (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)

      return param.domain
    }
  }

  static getDomainCanEditInCheckout (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)

      return param.local_d
    }
  }

  static getDomainIsTransfer (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)

      return param.trans === 'true' || param.trans === '1'
    }
  }

  // when entering the flow from the marketplace when pressing a certain sku, we want to incorporate that sku in the last addon page
  static getAddonSku (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)

      return param.addonsku
    }
  }

  // when paying with paypal we want to push the second payment (ugrade to 3year) to the addon screen.
  // If we need to upgrade pterm is set to the previous select term so we can calculate the difference
  static getPreviousTerm (history) {
    if (history.location.search) {
      const param = getQueryParametersFromUrl(history.location.search, true)

      if (!param.pterm) {
        return
      }

      return param.pterm
    }
  }

  static getPersonalInfoUrl (match, location, history, sku) {
    return makeUrl(match, location, history, 'personalinfo', sku)
  }

  static getUpgradeUrl (match, location, history, term, params) {
    const myTerm = term === undefined ? CheckoutUrlFlow.getTerm(match, history) : term
    return makeUrl(match, location, history, 'addons', `${CheckoutUrlFlow.getSku(match, history)}/${myTerm}`, params)
  }

  static getDomainUrl (match, location, history, term, params) {
    const myTerm = term === undefined ? CheckoutUrlFlow.getTerm(match, history) : term
    return makeUrl(match, location, history, 'domain', `${CheckoutUrlFlow.getSku(match, history)}/${myTerm}`, params)
  }

  static getConnectDomainUrl (match, location, history, params) {
    return makeUrl(match, location, history, 'domain-connect', null, params)
  }

  static getDomainSearchUrl (match, location, history) {
    return makeUrl(match, location, history, 'domain-search')
  }

  static getPlansUrl (match, location, history, domain, transfer) {
    const params = {}
    if (domain) {
      params.domain = domain
      params.local_d = true

      params.trans = !!transfer
    }

    const resourceId = getResourceId(match, location)

    const isPublicCheckout = location.pathname === '/domain-search2'
    const plansPageUrl = isPublicCheckout ? '/plans2' : '/plans'

    return mergeUrl(history, plansPageUrl + (resourceId ? `/${resourceId}` : ''), params)
  }

  static getDomainOrUpgradeUrl (match, location, history, term, couponCode, discountOnlyForPlansPage) {
    // Do not pass the coupon code when it is only for the plans page (and not for domains/cross-sell page)
    const params = couponCode && !discountOnlyForPlansPage ? { coupon: couponCode } : {}
    const myTerm = term === undefined ? CheckoutUrlFlow.getTerm(match, history) : term
    if (CheckoutUrlFlow.getDomainName(history) && !CheckoutUrlFlow.getDomainIsTransfer(history)) {
      return CheckoutUrlFlow.getDomainUrl(match, location, history, myTerm, params)
    } else {
      return CheckoutUrlFlow.getUpgradeUrl(match, location, history, myTerm, params)
    }
  }

  static getThanksUrl (match, location, history, term) {
    return makeUrl(match, location, history, 'thanks', `${CheckoutUrlFlow.getSku(match, history)}/${term}`)
  }

  static getSummaryUrl (history) {
    return mergeUrl(history, '/summary')
  }

  static cancel (history) {
    return CheckoutUrlFlow.goBack(history, true)
  }

  static goBack (history, doCancel, params) {
    // get the refering url from context param
    if (history.location.search) {
      const queryparams = getQueryParametersFromUrl(history.location.search, true)
      const returnUrl = params && params.returnurl ? params.returnurl : queryparams.returnurl
      let backUri = doCancel ? returnUrl : queryparams.success
      if (!backUri) {
        backUri = queryparams.success || returnUrl
      }

      if (doCancel && queryparams.uos && queryparams.uos.indexOf('editor') >= 0) {
        window.close()
        return
      }

      if (backUri && backUri.indexOf('http') === 0) {
        window.location.href = decodeURIComponent(backUri)
      } else {
        // When upgrading from the editor
        window.location.href = getBaseUrl(siteNames.MY)
      }
    }
  }

  static goBackToEditDomainInCP () {
    const controlPanelUrl = getBaseUrl(siteNames.MY)
    window.location.href = controlPanelUrl + '/domains'
  }

  // if we have a success return for order submit -> redirect (to paypal if required)
  static redirectForOrderSubmitResult (history, submitObj, getNextUrl, analyticsService, hardRefresh) {
    if (submitObj && submitObj.succeeded) {
      if (submitObj.paypal_url) {
        window.location.href = submitObj.paypal_url
      } else {
        const nextUrl = getNextUrl()

        if (has(submitObj, 'order_id')) {
          TrackAnEcomEventForOrderId(submitObj.order_id, analyticsService, () =>
            CheckoutUrlFlow.goToNextUrl(history, nextUrl, hardRefresh)
          )
        } else {
          CheckoutUrlFlow.goToNextUrl(history, nextUrl, hardRefresh)
        }
      }
      return true
    }
  }

  static goToNextUrl (history, nextUrl, hardRefresh) {
    if (hardRefresh) {
      window.location.href = nextUrl
    } else {
      history.replace(nextUrl)
    }
  }
}

export default CheckoutUrlFlow
