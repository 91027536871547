import React, { PureComponent } from 'react'
import { getQueryParametersFromUrl } from '@eig-builder/core-utils/helpers/url-helper'
import map from 'lodash/map'
import get from 'lodash/get'

import ComparePlansModalContainer from './../containers/compare-plans-modal'

class ComparePlansModal extends PureComponent {
  constructor () {
    super()
    this.state = { type: null }
  }

  static getDerivedStateFromProps (props, state) {
    if (state.limitation) {
      return null
    } else {
      const newState = getQueryParametersFromUrl(window.location.search)
      newState.limitation = JSON.parse(newState.limitation)
      newState.upgradeSkus = map(get(newState.limitation, 'upgrades'), (item) => item.sku)

      try {
        newState.extraInfo = JSON.parse(newState.extraInfo)
      } catch (ex) {}
      return newState
    }
  }

  render () {
    return <ComparePlansModalContainer {...this.state} />
  }
}

export default ComparePlansModal
