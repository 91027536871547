import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Routes
import OnboardingRoutes from '../../../modules/onboarding/routes/index'
import CheckoutRoutes from '../../../modules/checkout/routes/index'
import OnboardingDomainRoutes from '../../../modules/onboarding-domain/routes/index'
import DomainFlowRoutes from '../../../modules/domain-flow/routes/index'
import UpgradeModalsRoutes from '../../../modules/upgrade-modals/routes'
import LimitationRoutes from '../../../modules/limitation/routes'

// Price container, to supply currency symbol to all Price components
import { PriceContainer } from '@eig-builder/module-price'

import Bootstrapper from '@eig-builder/module-bootstrapper'
import { PropertyEditorContext } from '@eig-builder/module-property-editor'
import EventsContainer from '@eig-builder/module-application-events'
import {
  NotificationBarWrapperWithContext,
  NotificationBarContainer
} from '@eig-builder/module-notification-bar-with-context'
import { SnackbarProvider } from 'notistack'

import Select from '@eig-builder/module-property-editor-field-select'
import Autocomplete from '@eig-builder/module-property-editor-field-autocomplete'
import MaskedInput from '@eig-builder/module-property-editor-field-maskedinput'
import { getLoginUrl, getUrl, siteNames } from '@eig-builder/core-utils/helpers/url-helper'

import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import { setBuilderBar } from '@eig-builder/core-utils/helpers/builder-bar-helper'
import merge from 'lodash/merge'
// Theming
import { createMuiTheme } from '@material-ui/core/styles'
import BrandMuiTheme from 'muitheme'
import AnalyticsService, { getDefaultLoggers } from '@eig-builder/core-utils/analytics'
import AnalyticsAPI from './analytics-api/analytics-api'
const routes = [
  LimitationRoutes,
  OnboardingRoutes,
  CheckoutRoutes,
  OnboardingDomainRoutes,
  UpgradeModalsRoutes,
  DomainFlowRoutes
]
setBuilderBar(getUrl(siteNames.CP_CHECKOUT) + '/')

const BaseRouterWrapper = ({ children }) => (
  <NotificationBarWrapperWithContext>
    <PriceContainer>
      <EventsContainer>
        <PropertyEditorContext.Provider
          value={{
              additionalFieldTypes: [Select, Autocomplete, MaskedInput],
              useApiGateway: true
          }}
          >
          <NotificationBarContainer targetKey='fullscreen' />
          <SnackbarProvider hideIconVariant maxStack={3}>
            {children}
          </SnackbarProvider>
        </PropertyEditorContext.Provider>
      </EventsContainer>
    </PriceContainer>
  </NotificationBarWrapperWithContext>
)

BaseRouterWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default () => {
  const [theme, setTheme] = useState(createMuiTheme(BrandMuiTheme))
  const [key, setKey] = useState('0')
  useEffect(() => {
    const themeKey = window.runtimeConfig._brand === 'creativemail' ? 'creativemail' : 'builder'
    window.System.import(
      `branding/${themeKey}.js?hash=${
        window.runtimeConfig._brand + window.runtimeConfig._app + window.runtimeConfig._env
      }`
    )
      .then((module) => {
        if (module.default) {
          const innerTheme = merge(theme, module.default)
          setTheme(innerTheme)
          setKey('1')
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  //! Were mimicking the idea that there is a "shell", Warning! Your in a legacy
  //! framework that does not support the Shell. The following lines only purpose is to get google analytics to work..
  const pagePattern = JSON.parse(new URLSearchParams(window.location.search).get('extraInfo'))?.pagePattern
  window.builderShell = {}
  window.builderShell.analyticsApi = new AnalyticsAPI()
  window.builderShell.analyticsApi.init(false, pagePattern)
  window.authState = {
    getValue: () => {}
  }
  const analyticsService = new AnalyticsService(getDefaultLoggers(true)) // start using shell tracking
  //! end google analytics hack.

  return (
    <Bootstrapper
      key={key}
      muiTheme={theme}
      analyticsService={analyticsService}
      routes={routes}
      defaultRoute='/redirect'
      BaseRouterWrapper={BaseRouterWrapper}
      loginUrl={() => getLoginUrl()}
      AuthenticationContainerProps={{
        shouldLoadBillingInfo: true,
        shouldLoadIsEuCustomer: true,
        shouldLoadAccountInfo: true,
        limitationPartnerId: getRuntimeConfig()._partnerId
      }}
     />
  )
}
