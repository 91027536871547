import React, { memo, Fragment } from 'react'
import PropTypes from 'prop-types'
import Text from '@eig-builder/module-localization'
import UpgradeModalDisclaimer from '../upgrade-modal-disclaimer'
import UpgradeModalDomainPrice from './../upgrade-modal-cancelation-domain-price'

import styled from 'styled-components'

import './lang'

const MaxWidth = styled.div`
  max-width: 820px;
  text-align: center;
`

const ComparePlansModalDisclaimer = (props) => {
  const disclaimer = []
  if (props.hasPromotion) {
    disclaimer.push(<Fragment><Text message='modules.upgradeModels.comparePlansModalDisclaimer.promotion2' />&nbsp;
    </Fragment>)
  }

  disclaimer.push(<Text
    message='modules.upgradeModels.comparePlansModalDisclaimer.title'
    values={{
      price: <UpgradeModalDomainPrice currencyCode={props.currencyCode} />
    }}
                  />)

  return (<UpgradeModalDisclaimer
    title={<MaxWidth>&#42;{disclaimer}</MaxWidth>}
          />)
}

ComparePlansModalDisclaimer.propTypes = {
  currencyCode: PropTypes.string,
  hasPromotion: PropTypes.bool
}

export default memo(ComparePlansModalDisclaimer)
