import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  core: {
    cart: {
      utility: {
        domainTitle: 'Domain',
        domainSubTitleWithPrivacy: 'with domain privacy',
        domainSubTitleWithoutPrivacy: 'without domain privacy',
        siteTitle: 'Site'
      }
    }
  }
})
