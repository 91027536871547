import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  modules: {
    checkout: {
      routes: {
        myCheckout: 'My Checkout',
        connectDomain: 'Connect your domain'
      }
    }
  }
})
