import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  modules: {
    upgradeModels: {
      comparePlansModalDisclaimer: {
        title: 'Qualified plans include a free domain registration for one year. If you wish to cancel, there is a non-refundable {price} domain fee.',
        promotion2: 'The promotional price is for the first term of service only and automatically renews.',
        regularRates: 'regular rates.'
      }
    }
  }
})
