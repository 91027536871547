import React from 'react'
import PropTypes from 'prop-types'

import Modal from '@eig-builder/module-modal'

import Button from '@eig-builder/core-ui/Button'
import InlineNotification from '@eig-builder/core-ui/InlineNotification'

import Text from '@eig-builder/module-localization'
import useLocalize from '@eig-builder/module-localization/useLocalize'

import PricingSection from '../pricing-section/pricing-section.style'
import FeatureList from '../pricing-card/feature-list'
import { ALL_PLANS, ALL_MARKETING_FEATURES } from '../pricing-section/pricing-section.constants'

import PricingSliderCalculator from '@eig-builder/compositions-pricing-slider-calculator'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import useFetchAsJson from '@eig-builder/core-utils/hooks/useFetchAsJson'
import { api } from '@eig-builder/core-utils/helpers/url-helper'

import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'

import HeaderComponent from './header'
import { PriceCalculatorContainer, ModalFooterMessage } from './views'

import { useIsMobile } from '@eig-builder/core-utils/hooks/useResponsiveQuery'

import get from 'lodash/get'

import styled from 'styled-components'

const Bold = styled.span`
  font-weight: 700 !important;
`

const getPlans = (plans) => {
  return ALL_PLANS.map((plan) => {
    if (plan.sku !== 'PLAN') {
      // overwrite hardcoded price
      const price = plans.find((i) => i.sku === plan.sku)
      plan.header.info[1] = (price?.pricing[0].price / 100).toString()
    }
    return plan
  })
}

const CreativeMailUpgradeModal = ({ currentPlan, currencySymbol, onClickCheckout, onClose, plans }) => {
  const [open, setOpen] = React.useState(true)
  const [selectedPlan, setSelectedPlan] = React.useState('PLAN1_CE') // default selected plan
  const [upgradePlans, setUpgradePlans] = React.useState([])
  const { localize } = useLocalize()
  const saleActive = true

  React.useEffect(() => {
    if (plans.length > 0) {
      setUpgradePlans(getPlans(plans))
    }
  }, [plans])

  React.useEffect(() => {
    setSelectedPlan(currentPlan === 'PLAN1_CE' ? 'PLAN2_CE' : 'PLAN1_CE')
  }, [currentPlan])

  // Fetch prices to calculate price with pricing slider calculator
  const [prices] = useFetchAsJson({
    url: api('/v1.0/pricing/email-tiers', 'billing')
  })

  const handleOpenPriceCalculator = React.useCallback(
    (event) => {
      event.stopPropagation()
      setOpen(!open)
    },
    [open]
  )

  const handleOpenCompareFeatures = React.useCallback(
    (event) => {
      event.stopPropagation()
      setOpen(!open)
    },
    [open]
  )

  const handleClickCheckout = React.useCallback(() => {
    const plan = plans.find((plan) => plan.sku === selectedPlan)
    const price = get(plan, 'price', 0) / 100
    onClickCheckout(selectedPlan, plan.title, price)
  }, [selectedPlan, plans])

  const getMarks = React.useCallback(() => {
    if (prices) {
      return prices?.data.map((item, index) => {
        if (index === 0 || index === prices?.data.length - 1) {
          return {
            value: item.limit,
            price: item.price,
            label: item.limit
          }
        }
        return {
          value: item.limit,
          price: item.price
        }
      })
    }
  }, [prices])

  const getButtons = React.useCallback(
    () => [
      <Button
        key='creative-mail-cancel-button'
        variant='outlined'
        onClick={onClose}
        dataElementLocation={DataElementLocations.POP_UP}
        dataElementLabel='checkout--upgrade-modals--creativemail-cancel-button'
        dataElementId='checkout--upgrade-modals--creativemail-cancel-button'
      >
        <Text message='modules.upgradeModels.creativeEmailModal.cancelButton' />
      </Button>,
      <Button
        key='creative-mail-checkout-button'
        onClick={handleClickCheckout}
        dataElementLocation={DataElementLocations.POP_UP}
        dataElementLabel='checkout--upgrade-modals--creativemail-checkout-button'
        dataElementId='checkout--upgrade-modals--creativemail-checkout-button'
      >
        <Text message='modules.upgradeModels.creativeEmailModal.checkoutButton' />
      </Button>
    ],
    [onClose, handleClickCheckout]
  )

  const isMobile = useIsMobile()
  const actions = getButtons()

  return (
    upgradePlans && (
      <Modal
        open={upgradePlans.length > 0}
        onClose={onClose}
        maxWidth='md'
        variant='default'
        fullScreen={isMobile}
        fullWidth
        dataElementPrefix='checkout--upgrade-modal--creativemail-modal'
        >
        <Modal.Header bordered>
          <Modal.Alignment alignSelf='center'>
            <Modal.Header.Title title={localize('modules.upgradeModels.creativeEmailModal.modalTitle')} />
          </Modal.Alignment>
          <Modal.Alignment rightAligned>
            <Modal.Header.Close />
          </Modal.Alignment>
        </Modal.Header>

        <Modal.Content contentSpacing='large'>
          <div style={{ marginTop: '-30px' }}>
            <div className='py-2'>
              {currentPlan === 'PLAN' && <InlineNotification
                color='warning'
                message={
                  saleActive
                      ? (<div>
                        <Bold>{localize('modules.upgradeModels.creativeEmailModal.notificationMessage.s1')}</Bold>{' '}
                        {localize('modules.upgradeModels.creativeEmailModal.notificationMessage.s2')}{' '}
                        <Bold>{localize('modules.upgradeModels.creativeEmailModal.notificationMessage.s3')}</Bold>{' '}
                        {localize('modules.upgradeModels.creativeEmailModal.notificationMessage.s4')}{' '}
                        <Bold>
                          {currencySymbol}
                          {localize('modules.upgradeModels.creativeEmailModal.notificationMessage.s5')}
                        </Bold>
                      </div>
                      )
                      : (
                        <div>
                          <Bold>{localize('modules.upgradeModels.creativeEmailModal.notificationMessage.p1')}</Bold>{' '}
                          {localize('modules.upgradeModels.creativeEmailModal.notificationMessage.p2')}{' '}
                          <Bold>{localize('modules.upgradeModels.creativeEmailModal.notificationMessage.p3')}</Bold>{' '}
                          {localize('modules.upgradeModels.creativeEmailModal.notificationMessage.p4')}{' '}
                          <Bold>
                            {currencySymbol}
                            {localize('modules.upgradeModels.creativeEmailModal.notificationMessage.p5')}
                          </Bold>
                        </div>
                )
}
                icon={<CardGiftcardIcon />}
              />}
            </div>
            <div>
              <PricingSection>
                <PricingSection.CardContainer
                  zIndex='-1'
                  gridAutoFlow='column'
                  gridColumn='1 / span 4'
                  alignItems='flex-end'
                  display='flex'
                  gridRow='1 / span 3'
                  isOpen={open}
                >
                  {open && (
                    <PricingSection.EmailMarketingTitle>
                      <Text message='modules.upgradeModels.creativeEmailModal.featuresTitle' />
                    </PricingSection.EmailMarketingTitle>
                  )}
                </PricingSection.CardContainer>
                {open && (
                  <PricingSection.FeaturesWrapper gridColumn='1 / span 4' gridRow={4}>
                    <PricingSection.FeaturesContainer isMarketingRow isOpen={open}>
                      <FeatureList features={ALL_MARKETING_FEATURES} />
                    </PricingSection.FeaturesContainer>
                  </PricingSection.FeaturesWrapper>
                )}
                {upgradePlans.map(({ sku, header, features, disabled }, index) => {
                  const isActive = sku === selectedPlan
                  return (
                    <React.Fragment key={`${header.plan}-${index}`}>
                      <HeaderComponent
                        index={index}
                        header={header}
                        disabled={disabled || sku === currentPlan}
                        isActive={isActive}
                        features={features}
                        setSelectedPlan={() => setSelectedPlan(sku)}
                        open={open}
                        handleOpenPriceCalculator={handleOpenPriceCalculator}
                        handleOpenCompareFeatures={handleOpenCompareFeatures}
                        isOpen={open}
                        currencySymbol={currencySymbol}
                        isCurrentPlan={sku === currentPlan}
                      />
                      <PricingSection.FeaturesContainer
                        border
                        isActive={isActive}
                        gridColumn={index + 2}
                        gridRow={4}
                        alignItems='center'
                        justifyCenter
                        zIndex={2}
                        isOpen={open}
                      >
                        {open && (
                          <FeatureList
                            features={features}
                            isActive={isActive}
                            setSelectedPlan={() => !(disabled || sku === currentPlan) && setSelectedPlan(sku)}
                            disabled={disabled || sku === currentPlan}
                          />
                        )}
                      </PricingSection.FeaturesContainer>
                    </React.Fragment>
                  )
                })}
              </PricingSection>
              {!open && (
                <PriceCalculatorContainer>
                  <PricingSliderCalculator
                    title={localize('modules.upgradeModels.creativeEmailModal.title')}
                    subTitle={localize('modules.upgradeModels.creativeEmailModal.subtitle')}
                    defaultValue={0}
                    marks={getMarks()}
                    steps={null}
                    singleCard
                    currencySymbol={currencySymbol}
                  />
                </PriceCalculatorContainer>
              )}
            </div>
          </div>
        </Modal.Content>

        {actions && actions.length > 0 && (
          <Modal.Footer bordered>
            <Modal.Alignment alignSelf='center'>
              <ModalFooterMessage variant='body2'>
                <Text message='modules.upgradeModels.creativeEmailModal.footerText' />
              </ModalFooterMessage>
            </Modal.Alignment>
            <Modal.Alignment rightAligned>{actions}</Modal.Alignment>
          </Modal.Footer>
        )}
      </Modal>
    )
  )
}

CreativeMailUpgradeModal.propTypes = {
  currentPlan: PropTypes.string,
  currencySymbol: PropTypes.string.isRequired,
  onClickCheckout: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired
}

export default CreativeMailUpgradeModal
